import { Col, Container, Row, OverlayTrigger, Tooltip, Button, Table, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ThreeDotSpinner } from "../loader";
import { apiBaseUrl, apiUrl, FilterStatus, getStatus,formatToColombianCurrency } from "../../config/config";
import { Link } from "react-router-dom";
import userImg from "../../assets/images/byDefaultUser.png"
import Pagination from "react-js-pagination";
import { apiService } from "../../service/api.service";
import swal from "sweetalert";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const OrderList = () => {
    const { t } = useTranslation();
    const [orderLIst, setOrderList] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0)
    const [delivery_status, setDelivery_status] = useState(null)
    const [getDeliveryStatus, setGetDeliveryStatus] = useState("")
    const [delivery_type, setDelivery_type] = useState("")
    const [search, setSearch] = useState(null)
    const [size, setsize] = useState(10)
    const tooltip = (
        <Tooltip id="tooltip">
            {t("view_detail")}
        </Tooltip>
    );
    useEffect(() => {
        getOrderlist(search, page, delivery_status, size)
    }, [])
    function download_csv() {
        window.open(apiUrl + "/download_csv/?type=order")

    }
    async function getOrderlist(search, page, status, size) {
        setLoader(true)
        try {

            const response = await apiService.getOrders(page, size, status, search)
            console.log(">>>>>>>>>>>>ordersssslist>>>>........", response)
            if (response?.status == 200) {

                setOrderList(response.data.data.list)
                setTotalItems(response.data.data.total)


                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            if (error?.response?.status == 401) {
                setLoader(false)
                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                });
            } else {
                if (error?.response?.status == 403) {
                    setLoader(false)
                    swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                        (value) => {
                            localStorage.clear()
                            window.location.href = "/";
                        }
                    );
                }else{
                    setLoader(false)
                    swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                        (value) => {
                            localStorage.clear()
                          window.location.href = "/";
                        }
                    );
                }
               
            }
        }
    }



    function handlePageChange(page) {
        console.log("=====page===", page)
        setPage(page - 1);
        getOrderlist(search, page - 1, delivery_status, size)
    }






    function handleUpdateStatus(status) {
        console.log(status)
        console.log(delivery_status)
        if (status == "All" && (delivery_status !== "All" && delivery_status !== null)) {
            setDelivery_status(null)
            setPage(0);
            getOrderlist(search, 0, null, size)
        } else if (status != "All") {
            setDelivery_status(status)
            setPage(0);
            getOrderlist(search, 0, status, size)
        }


    }
    function handleUpdateSearch(e) {
    // if(e.trim()!=""){
        setSearch(e)
        setPage(0);

    // }
     
        // getOrderlist(e, 0, delivery_status, size)
    }
    function handleClearSearch(e) {
        console.log("search", e)
        setSearch(e)
        setPage(0);
        getOrderlist(e, 0, delivery_status, size)
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && search && search.trim() != "") {
            // Call your function here
            // setPage(0);
            getOrderlist(search, page, delivery_status, size)
        }
    };
    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}

                <Container fluid>
                    <div className="dashboard-area-heading management-heading">

                        <Row className="row mb-3 justify-content-center align-items-center">
                            <Col md={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex">
                                        <h2 className="align-self-end m-0"> {t("orders")} </h2>
                                    </div>
                                    <div className="heading-top-area-right">
                                        <div className="search-area position-relative update-search-box">
                                            {/* <label>Search Order Number</label> */}
                                            <form
                                                autoComplete="off"
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                }}
                                            >
                                                <input
                                                    placeholder={t("order_search")}
                                                    type="text"
                                                    className="form-control inner-input"
                                                    onChange={(e) => handleUpdateSearch(e.target.value)}
                                                    onKeyDown={handleKeyPress}
                                                    value={search==null?"":search}
                                                // onKeyDown={(e) => {

                                                // if (
                                                //     !/^\d$/.test(e.key) && e.key !== "Backspace" || (e.key === " " && e.target.value === "")) {
                                                //     e.preventDefault();
                                                // }
                                                // }

                                                />
                                                <i
                                                    className="fa fa-search"
                                                    style={{ cursor: "pointer" }}
                                                    aria-hidden="true"
                                                    onClick={() => search!==null && search.trim() != ""?getOrderlist(search, page, delivery_status, size):null}
                                                ></i>
                                                {search && search != null  && search.trim() != "" && <i className="fa fa-close" aria-hidden="true" onClick={() => handleClearSearch(null)}></i>}
                                            </form>
                                        </div>

                                        <div>
                                            <label> {t("delivery_status")}</label>
                                            <div className="arrow-down-area">
                                                <Form.Select aria-label="Default select example" onChange={(e) => handleUpdateStatus(e.target.value)}>
                                                    {/* <option value={"All"}>Select Status </option> */}
                                                    <option value={"All"}>{t("all")}</option>
                                                    {FilterStatus && FilterStatus.map((data) => {
                                                        return (<option value={data.value}>{t(data.label)}</option>)
                                                    })}

                                                </Form.Select>

                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        {/* <div className="export-csv-btn">
                                             <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                                                                      </div> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">

                                        <thead>
                                            <tr>
                                                <th>{t("otder_id")}</th>
                                                {/* <th>Product Name</th> */}
                                                <th>{t("otder_date")}</th>
                                                <th>{t("customer_name")}</th>
                                                <th>{t("driver_name")}</th>
                                                <th>{t("status")}</th>
                                                <th>{t("amount")}</th>
                                                <th>{t("usedcredit")}</th>
                                                
                                                <th>{t("action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderLIst && orderLIst.map((data, i) => {
                                                let status = getStatus(data.status)

                                                return (<tr>
                                                    <td>#{data.orderId}</td>

                                                    <td>{moment(data.orderDate).format("DD MMM, YYYY")}</td>
                                                    <td>{data.customer?.fullName}</td>
                                                    <td>{data.driverName ? data.driverName : "N/A"}</td>

                                                    <td>
                                                       
                                                          <>
                                                          <span className={(t(status) == "Pending") ? "delivered pending":(t(status) == "Order Delivered") ? "delivered" : (t(status) == "Order Cancelled") ? "delivered pending" : (t(status) == "Driver Not Assigned") ? "delivered not-assigned" : (t(status) == "Driver Assigned") ? "delivered driver-assigned" : "delivered accepted" }></span>
                                                          {status ? t(status) : ""}

                                                          </>

                                                        {/* {status == "Pending" ?
                                                          
                                                            <>
                                                                
                                                                </> :

                                                            <>
                                                                {status == "Order Delivered" ? <>
                                                                    <span className="delivered"></span>
                                                                    {status ? t(status) : ""}</> :
                                                                    <>

                                                                        {status == "Order Cancelled" ?
                                                                            <>
                                                                                <span className="delivered pending"></span>
                                                                                {status ? t(status) : ""}
                                                                            </>

                                                                            :
                                                                            <>
                                                                                {status == "Driver Not Assigned" ?
                                                                                    <> <span className="delivered not-assigned"></span>
                                                                                        {status ? t(status) : ""}</>
                                                                                    :
                                                                                    <>
                                                                                        {status == "Driver Assigned" ?
                                                                                            <> <span className="delivered driver-assigned"></span>
                                                                                                {status ? t(status) : ""}</> :
                                                                                            <>

                                                                                                <span className="delivered accepted"></span>
                                                                                                {status ? t(status) : ""}
                                                                                            </>}
                                                                                    </>
                                                                                }
                                                                            </>

                                                                        }
                                                                    </>}

                                                            </>} */}

                                                    </td>
                                                    <td>${formatToColombianCurrency(data.totalAmount)}</td>
                                                    <td>-${formatToColombianCurrency(data.creditsUsed)}</td>
                                                    <td>
                                                        <div className='action-data-box'>
                                                            <OverlayTrigger placement="top" overlay={tooltip}>
                                                                <Link to={"/order-mangement/order-details/" + data.orderId}>
                                                                    <div className="edit-pencil">
                                                                        <i class="fa fa-eye" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                                                    </div>
                                                                </Link>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                </tr>
                                                )
                                            }
                                            )}
                                           

                                            {orderLIst && orderLIst.length == 0 &&
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="text-center">
                                                            <div className="no-data-found-section">
                                                                <img src={require("../../assets/images/no-data.svg").default} alt="" />
                                                                <h5><b>{t("no_data_found")}</b></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                        </tbody>

                                    </Table>
                                    {orderLIst.length > 0 && totalItems > size ?

                                        <Pagination
                                            activePage={page + 1}
                                            itemsCountPerPage={size}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
