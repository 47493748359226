import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";

import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
// CSS
import "./App.css";
import "./assets/css/style.css";
import { MyContext } from "./config/config";

import { UsersList } from "./components/users/UsersList";
import { Products } from "./components/productManagement/Products";
import { Profile } from "./components/profile/AdminProfile";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { ProductDetail } from "./components/productDetail/ProductDetail.js";
import { TransactionManagement } from "./components/transactionManagement/TransactionManagement.js";
import { ContactUs } from "./components/users/ContactUs";
import { Category } from "./components/category/Category";
import { Unit } from "./components/units/Unit.js";
import { UserDetails } from "./components/users/UserDetails.js";
import { CultivatorDetails } from "./components/users/CultivatorDetails.js";
import { DriverDetail } from "./components/users/DriverDetail.js";
import { OrderList } from "./components/order/OrderList.js";
import { OrderDetails } from "./components/order/OrderDetails.js";
import { Settings } from "./components/settings/Settings.js";
import { GeofenceMap } from "./components/settings/geoFancing.js";
import { TranslationManagement } from "./components/translationManagement/TranslationManagement.js";




function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [openLoad, setOpenLoad] = useState(false);
  const [editProfile, setEditProfile] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("admin_token")) {
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MyContext.Provider value={{ affiliateRequest, setAffiliateRequest, filterType, setFilterType, openLoad, setOpenLoad, editProfile, setEditProfile }}>
      <>
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/products"
            element={
              <ProtectedRoute>
                <Products />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <Category />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/units"
            element={
              <ProtectedRoute>
                <Unit />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/transaction"
            element={
              <ProtectedRoute>
                <TransactionManagement />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/translation"
            element={
              <ProtectedRoute>
                <TranslationManagement />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/transaction/:address"
            element={
              <ProtectedRoute>
                <TransactionManagement />
              </ProtectedRoute>}
          >
          </Route>
          
          <Route
            path="/product-detail/:id"
            element={
              <ProtectedRoute>
                <ProductDetail />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/contact-us"
            element={
              <ProtectedRoute>
                <ContactUs/>
              </ProtectedRoute>}
          >
          </Route>
        
          <Route
            path="/ChangePassword"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/settings/geo"
            element={
              <ProtectedRoute>
                <GeofenceMap />
              </ProtectedRoute>}
          >
          </Route>
          
    
          <Route
            path="/Admin"
            element={<ProtectedRoute>
              <Profile />
            </ProtectedRoute>}
          > </Route>

           <Route
            path="/cultivator-details/:id"
            element={<ProtectedRoute>
              <CultivatorDetails />
            </ProtectedRoute>}
          > </Route>
            <Route
            path="/driver-details/:id"
            element={<ProtectedRoute>
              <DriverDetail />
            </ProtectedRoute>}
          > </Route>
           <Route
            path="/user-details/:id"
            element={<ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/users"
            element={<ProtectedRoute>
              <UsersList />
            </ProtectedRoute>}
          > </Route>
 <Route
            path="/order-mangement/orders"
            element={<ProtectedRoute>
              <OrderList />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/order-mangement/order-details/:orderId"
            element={<ProtectedRoute>
              <OrderDetails />
            </ProtectedRoute>}
          > </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}

      </>
    </MyContext.Provider>
  );
}

export default App;
