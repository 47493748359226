import React, { useContext, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { MyContext } from "../config/config";
import ReactLanguageSelect from 'react-languages-select';
import 'react-languages-select/css/react-languages-select.css';
import { useTranslation } from 'react-i18next';
import { apiService } from "../service/api.service";
import i18n from 'i18next';
import '../i18n';
const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {editProfilr, setEditProfile}=useContext(MyContext)
  const selectedlanguage = localStorage.getItem("lang")
  console.log("=====selectedlanguage===>>>",selectedlanguage)
  const changeLanguage = (lng) => {
    console.log(lng)
    localStorage.setItem("lang",lng)
    languageChange(lng)
    i18n.changeLanguage(lng);
  };
  const languageChange = (lng)=>{
    apiService.changeLanguage(lng).then((res) => {
      console.log(res)
     window.location.reload()
    
    }).catch((err) => { 
    });
  }
  
  return (
    <>
      <div className="admin-header">
        <Container fluid>
          <div className="header-right">
            <div className="logo-mob">
             <img src={require("../assets/images/logo-mob.png")} alt="img" />
            </div>
            <ReactLanguageSelect onSelect ={(e)=>changeLanguage(e)} defaultLanguage={selectedlanguage} languages={["en", "es"]} customLabels={{"en": "English", "es": "Spanish"}} placeholder="Change Language"  className="select-language" />
            <div className="logout-btn">
           
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={require("../assets/images/user.png")} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                {/* <Dropdown.Item
                onClick={()=>{setEditProfile(false);navigate("/Admin")}}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>
                    My Profile
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item
                    onClick={()=>{setEditProfile(true);navigate("/Admin")}}
                  >
                    <i class="fa fa-pencil-square" aria-hidden="true"></i>
                    Edit Profile
                  </Dropdown.Item> */}
                <Dropdown.Item
                    onClick={()=>{navigate("/ChangePassword")}}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    {t("changepass")}
                  </Dropdown.Item>
                  <Dropdown.Item
                   onClick={()=>{
                    swal({
                      title: t("are_you_sure"),
                      text: t('logout_confirm'),
                      icon: 'warning',
                      buttons: [t('cancel'), t('logout_yes')],
                      dangerMode: true,
                    }).then((confirmed) => {
                      if (confirmed) {

                        localStorage.clear();
                        localStorage.setItem("lang",selectedlanguage)
                        navigate("/");
                        
                      }
                    })
                   }}
                    
                  >
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                  {t("logout")}
                  </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
         
        </Container>
      </div>
    </>
  );
};
export default Header;
