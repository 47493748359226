import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { apiService } from "../../service/api.service";
import { InlineLoader } from "../../components/loader";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const ChangePassword = () => {
  const { t } = useTranslation();
  const md5 = require("md5");
  const [loader, setLoader] = useState(false);
  const [apierror, setApierror] = useState("");
  const navigate = useNavigate();
  const [oldpassword, setOldpassword] = useState("");
  const [oldpassworderror, setOldpassworderror] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [newpassworderror, setNewpassworderror] = useState("");
  const [cpassworderror, setcpassworderror] = useState("");
  const PASSWORDREGEX =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [type, setType] = useState("password");
  const [newtype, setNewType] = useState("password");
  const [confirntype, setConfirmType] = useState("password");
  /*-----------onChange old password----------*/
  function onChangeOldPassword(e) {
    console.log("old password.......>>>>>", e);
    const data = e;
    setOldpassword(data);
    if (!data) {
      setOldpassworderror(t("old_pass_valid"));
    } else {
      setOldpassworderror("");
    }
  }

  /*-----------onChange new password----------*/

  function onChangeNewPassword(e) {
    console.log("new password........>>>>>", e);
    const data = e;
    setNewpassword(e);
    if (!data) {
      setNewpassworderror(t("new_pass_valid"));
    } else if (!PASSWORDREGEX.test(data)) {
      setNewpassworderror(
        t("pass_regex")
      );
    } else {
      setNewpassworderror("");
    }
  }

  const handleSubmit = (e) => {
    const data = {
      oldPassword: oldpassword,
      password: newpassword,
      confirmPassword: cpassword,
    };
    e.preventDefault();
      let formValid =true
    if (!oldpassword) {
      formValid= false
      setOldpassworderror(t("old_pass_valid"));
    } else if (!newpassword) {
      setOldpassworderror("")
      formValid= false
      setNewpassworderror(t("new_pass_valid"));
    } else if (!PASSWORDREGEX.test(newpassword)) {
      setOldpassworderror("")
      formValid= false
      setNewpassworderror(
        t("pass_regex")
      );
    } else if (!cpassword) {
      setOldpassworderror("")
      setNewpassworderror("");
      formValid= false
      setcpassworderror(t("confirm_pass_valid"));
    } else if (newpassword != cpassword) {
      setOldpassworderror("")
      setNewpassworderror("");
      formValid= false
      setcpassworderror(t("confirm_pass_valid_same"));
    } else {
      formValid= true
      setOldpassworderror("")
      setcpassworderror("")
      setNewpassworderror("");
    }

    if (formValid) {
      
      swal({
        title: t("are_you_sure"),
        text: t("confirm_message"),
        icon: "warning",
        buttons: [t("cancel"), t("yes_confirm")],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          setLoader(true);
          apiService
            .changePassword(data)
            .then((res) => {
              if (res?.status == 200) {
                setLoader(false);
                console.log("changepassword.......", res);
                swal("success", res?.data?.message, "success").then((value) => {
                  localStorage.clear();
                  setTimeout(()=>{
                    navigate("/");

                  },1000)
                });
              }
            })
            .catch((error) => {
              setApierror(error?.message);
              console.log("catch errrorrrrrr....", error);
              if (error?.response?.status == 401) {
                if(error?.response?.data?.token){
                   localStorage.setItem("admin_token", error?.response?.data?.token);
                  window.location.reload()
                }else{
                  swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                  });
                }
                setLoader(false)
               
            }else {
              swal({icon: 'error', text: error?.response.data.message, button: "OK", }).then((value) => {
                setLoader(false)
              })
            }
            });
        }
      });
    }
  };

  return (
    <>
      <div className="dashboard-main-area">
            <div className="change-section">
        {loader ? <ThreeDotSpinner className="loginPageLoader" /> : ""}
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={7} xl={6}>
              <div className="change-password-area">
                <h2 className="chamge-text">{t("changepass")}</h2>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("old-pass")}</Form.Label>
                    <div className="password-area">
                    <Form.Control
                      type={type}
                      placeholder={t("enter_old-pass")}
                      onChange={(e) => onChangeOldPassword(e.target.value)}
                      onKeyDown={(e) =>{
                        if(!e.target.value && e.code === "Space"){
                        e.preventDefault();
                      }
                    }}
                    />
                    {type == "password"?
                          <i class="fa fa-eye"  style={{cursor:"pointer"}} aria-hidden="true" onClick={()=>setType("text")}></i>:
                          <i class="fa fa-eye-slash" style={{cursor:"pointer"}} aria-hidden="true" onClick={()=>setType("password")}></i>}
                   </div>
                    <span className="text-danger">{t(oldpassworderror)}</span>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("new_pass")}</Form.Label>
                    <div className="password-area">
                    <Form.Control
                      type={newtype}
                      placeholder={t("enter_new_pass")}
                      onChange={(e) => onChangeNewPassword(e.target.value)}
                      onKeyDown={(e) =>{
                        if(!e.target.value && e.code === "Space"){
                        e.preventDefault();
                      }
                    }}
                    />
                                              {newtype == "password"?
                          <i class="fa fa-eye"  style={{cursor:"pointer"}} aria-hidden="true" onClick={()=>setNewType("text")}></i>:
                          <i class="fa fa-eye-slash" style={{cursor:"pointer"}} aria-hidden="true" onClick={()=>setNewType("password")}></i>}
                    </div>
                    
                    <span className="text-danger">{t(newpassworderror)}</span>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("confirm_pass")}</Form.Label>
                    <div className="password-area">
                    <Form.Control
                      type={confirntype}
                      placeholder={t("enter_confirm_pass")}
                      onChange={(e) => setcpassword(e.target.value)}
                      onKeyDown={(e) =>{
                        if(!e.target.value && e.code === "Space"){
                        e.preventDefault();
                      }
                    }}
                    />
                                              {confirntype == "password"?
                          <i class="fa fa-eye"  style={{cursor:"pointer"}} aria-hidden="true" onClick={()=>setConfirmType("text")}></i>:
                          <i class="fa fa-eye-slash" style={{cursor:"pointer"}} aria-hidden="true" onClick={()=>setConfirmType("password")}></i>}
                    </div>
                    <span className="text-danger">{t(cpassworderror)}</span>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="login-btn-submit">
                    {loader ? <InlineLoader /> : t("submit")}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
        </div>
  
    </>
  );
};