import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip
 } from "react-bootstrap";
import { ThreeDotSpinner } from "../loader";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import moment from "moment";
import { baseUrl, dateFormate, UtctolocalDate } from "../../config/config";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = (msg) => {
    setMessage(msg)
    setShow(true);
  }

  const tooltip = (
    <Tooltip id="tooltip">
      {t("view")}
    </Tooltip>
  );
  // const { filterType, setFilterType } = useContext(MyContext)
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0)
  const [type, setType] = useState("")
  const [limit, setlimit] = useState(10)
 useEffect(() => {
    contactUs(page, limit)
  }, [])

  async function contactUs(page, limit) {
    setLoader(true)
    try {

      const response = await apiService.contactUs(page, limit)
      if (response?.status == 200) {

        let responseData = response.data.data.list
        setUserData(responseData)
        setTotalItems(response?.data?.data.total)

        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber - 1);
    contactUs(pageNumber - 1,limit)
  }

  //   function handleUpdateSearch(search) {
  //     setSearch(search);
  //     setPage(0);
  //     contactUs(page)
  //   }









  const formatAddress = (addr) => {
    return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`
  }

  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">{t("contact_us")}</h2>
                  </div>
                  <div className="heading-top-area-right">

                    <div className="search-area">
                  
                    </div>
                   
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>{t("sr_no")}</th>

                        <th>{t("name")}</th>
                        <th>{t("email")}</th>
                        <th className="email-section">{t("phone_number")}</th>
                        <th>{t("message")}
                          </th>
                        {/* <th>Source</th> */}
                        <th>{t('created_at')}</th>
                        {/* <th>Key Note</th> */}
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData.map((data, index) => {
                          return (<tr key={index}>
                            <td>{(index + 1)}</td>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data?.phone}</td>
                            <td className="contact-msg update_contact_message">{data.message && data.message.length > 50 ? data.message.slice(0, 50) + "..." : data.message}
                              {data.message && data.message.length > 50 ? <a href="#" onClick={() => handleShow(data.message)}>{t("view_more")}</a> : null}
                            </td>
                            <td>{moment(UtctolocalDate(data?.createdAt)).format(dateFormate)}</td>
                          </tr>)
                        })) : (
                        <tr>
                          <td colSpan={6}>
                            <div className="text-center">
                              <div className="no-data-found-section">
                                <img src={require("../../assets/images/no-data.svg").default}
                                  alt="da"
                                />
                                <h5>
                                {t("no_data_found")}
                                </h5>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  {totalItems
                  
                  
                  && totalItems > limit ?

                    <Pagination
                      activePage={page + 1}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* modal */}
      <Modal show={show} onHide={handleClose} centered className="message-content-popup">
        <Modal.Header closeButton>
          <Modal.Title> {t("message")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t("ok")}
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};
