import { Col, Row, Table, Button, Form, Modal } from "react-bootstrap";
import { useState } from 'react';
import { apiService } from "../../service/api.service";
import { useTranslation } from 'react-i18next';
import { purposeTranslation } from "../../config/config";
import '../../i18n';
import swal from "sweetalert";

export const TranslationManagement = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(true);
  const [Unit, setUnit] = useState([]);


  async function UnitDetail(page, search) {
    try {
      setLoader(true)
      const params = {
        page: page,
        search: search
      }
      const response = await apiService.GetUnitList(params)
      console.log("Unit...", response)
      if (response?.status == 200) {
        setUnit(response?.data?.data.list)
        setLoader(false)
        // setTotalCount(response?.data?.total_count)
      }

    } catch (error) {
      console.log("????????", error)
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload()
        } else {
          if (error?.response?.status == 403) {
            setLoader(false)
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                (value) => {
                    localStorage.clear()
                    window.location.href = "/";
                }
            );
        }else{
            setLoader(false)
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                (value) => {
                    console.log('OK button clicked after error alert');
                }
            );
        }
        }


        setLoader(false)

      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }
  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="heading-top-area mb-3">
                <div>
                  <h2 className="main-heading mt-0">Translation Management</h2>
                </div>
              </div>
              <Row>
                <Col md={8}>
                  <div className="audit-request-box categories-boxes">
                    <Row className="pb-4 justify-content-center">
                      <Col md={12} lg={12}>
                        <div className="availabilit-section">
                          <Table responsive="sm" className="UserListTable">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Purpose</th>
                                <th>Translation Key</th>
                                <th>Translation</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>01</td>
                                <td>Unit</td>
                                <td>Key 01</td>
                                <td>Spanish language</td>
                                <td><Button onClick={handleShow} className="UnitBtn cat_Edit categoryBtn">Edit</Button><Button className="categoryBtn cat_Delete">Delete</Button></td>
                              </tr>
                              <tr>
                                <td>01</td>
                                <td>Unit</td>
                                <td>Key 01</td>
                                <td>Spanish language</td>
                                <td><Button onClick={handleShow} className="UnitBtn cat_Edit categoryBtn">Edit</Button><Button className="categoryBtn cat_Delete">Delete</Button></td>
                              </tr>
                              <tr>
                                <td>01</td>
                                <td>Unit</td>
                                <td>Key 01</td>
                                <td>Spanish language</td>
                                <td><Button onClick={handleShow} className="UnitBtn cat_Edit categoryBtn">Edit</Button><Button className="categoryBtn cat_Delete">Delete</Button></td>
                              </tr>
                              <tr>
                                <td>01</td>
                                <td>Unit</td>
                                <td>Key 01</td>
                                <td>Spanish language</td>
                                <td><Button onClick={handleShow} className="UnitBtn cat_Edit categoryBtn">Edit</Button><Button className="categoryBtn cat_Delete">Delete</Button></td>
                              </tr>
                              <tr>
                                <td>01</td>
                                <td>Unit</td>
                                <td>Key 01</td>
                                <td>Spanish language</td>
                                <td><Button onClick={handleShow} className="UnitBtn cat_Edit categoryBtn">Edit</Button><Button className="categoryBtn cat_Delete">Delete</Button></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="categories-add-box">
                    <h3>Add Translation</h3>
                    <div className="position-relative">
                      <i class="fa-solid fa-chevron-down"></i>
                      <Form.Select className="w-100 form-main-select" aria-label="Default select example">
                    <option>{t("select_purpos")}</option>
                    {purposeTranslation.map((data)=>{
                      return(<option value={data.value}>{data.label}</option>)

                    })}
                  
                  </Form.Select>
                    </div>
                    <div className="my-4">
                      <label>Translation</label>
                      <textarea placeholder="Translation" className="form-control"> </textarea>
                    </div>
                    <Button variant="primary" className="add-btns">
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="add-pediction-area" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Translation Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="transation-section">
            <Form>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>
                {t("select_purpos")}
                </Form.Label>
                <div className="position-relative">
                  <i class="fa-solid fa-chevron-down"></i>
                  <Form.Select className="w-100 form-main-select" aria-label="Default select example">
                    <option>{t("select_purpos")}</option>
                    {purposeTranslation.map((data)=>{
                      return(<option value={data.value}>{data.label}</option>)

                    })}
                  
                  </Form.Select>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                Translation
                </Form.Label>
                <textarea placeholder="Translation" className="form-control"> </textarea>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="secondary" className="close-btn-bottom" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};