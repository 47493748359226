import { Col, Container, OverlayTrigger, Row, Table, Tooltip, Tabs, Tab } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import { baseUrl, dateFormate, formatBalance, formatToColombianCurrency } from "../../config/config";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
// import copy from "copy-to-clipboard";
import { apiService } from "../../service/api.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const TransactionManagement = () => {
  const { t } = useTranslation();
  let history = useNavigate();
  const [loader, setLoader] = useState(false)
  const [transactionData, settransactionData] = useState()
  const [page, setpage] = useState(0)
  const [size, setsize] = useState(10)
  const [defaultTab, setrolTab] = useState("RECEIVABLE")
  const [totalItems, settotalItem] = useState()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, settype] = useState("RECEIVABLE");

  useEffect(() => {
    getTransaction(page, size, type)
  }, [])
  const handleApply = () => {
    getTransaction(page, size, type)
    // Do something with startDate and endDate, like applying filters
    console.log("Selected start date:", startDate);
    console.log("Selected end date:", endDate);
  };
  function handlePageChange(pageNumber) {
    setpage(pageNumber - 1);
    getTransaction(pageNumber - 1, size, type)
  }
  const getTransaction = (page, size, paymenttype) => {
    setLoader(true)
    let queryParam = `?type=${paymenttype}`
    if (startDate && endDate && type==paymenttype) {
      queryParam = `?type=${paymenttype}&from=${startDate}&to=${endDate}`
    }

    apiService.orderTransaction(page, size, queryParam).then((res) => {
      
      console.log("======Get=trans data====>", res.data.data)
      settotalItem(res.data.data.total)
      setLoader(false)
      settransactionData(res.data.data.list)


      // setbankList(res.data.data)

    }).catch((error) => {
      
      setLoader(false)
      if (error?.response?.status == 401) {
        setLoader(false)

        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          // window.location.reload()
        } else {
          swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        }
      } else {
        if (error?.response?.status == 403) {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
                  localStorage.clear()
                  window.location.href = "/";
              }
          );
      }else{
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
             
              }
          );
      }
      }
      // setupdateShow(true)
    });
  }
  const tooltip = (
    <Tooltip id="tooltip">
      View
    </Tooltip>
  );
  const setcopytext = () => {
    // setTimeout(() => {
    //   setcopied(false)
    // }, "1000");

  }
  const copytooltip = (
    <Tooltip id="tooltip">
      {/* {copied?"Copied" :"Copy"} */}
    </Tooltip>

  );
  function handleSelect(key) {
    setStartDate(null)
    setEndDate(null)
    settype(key)
   
    getTransaction(page, size, key)
    // if (role != key) {
    //   setSearchValue("")
    //   setrole(key)
    //   setPage(0)
    //   get_users(0, size, key, "", statusEnabled)
    // }
  }
  function clear() {
setStartDate(null)
setEndDate(null)
console.log("----",type)
    getTransaction(page, size, type)
  }
  const handleStartDateChange = date => {
    console.log("=====date===", date)
    date = new Date(date)
    console.log("=====date===", date)
    let dt = moment(date).format("YYYY-MM-DD")
    console.log("=====dt===", dt)
    setStartDate(dt);
  }
  const handleEndDateChange = date => {
    date = new Date(date)
    console.log("=====dt===", date)
    let dt = moment(date).format("YYYY-MM-DD")
    console.log("=====dt===", dt)
    setEndDate(dt);
  }
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="transaction-management-top">
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">{t("transaction_management")}</h2>
                  </div>

                </div>
                <div className="heading-top-area-right date-range-filter">

                  <label>{t("from")}:</label>
                  <DatePicker
                    selected={startDate}
                    onChange={date => handleStartDateChange(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={t("from")}
                    // dateFormat="YYYY-MM-DD"
                  />
                  <label>{t("to")}:</label>
                  <DatePicker
                    selected={endDate}
                    onChange={date => handleEndDateChange(date)}
                    // selectsEnd
                     startDate={startDate}
                     endDate={endDate}
                    minDate={startDate}
                    placeholderText={t("to")}
                    //  dateFormat="YYYY-MM-dd"
                  />
                  <button onClick={handleApply} className="apply-top-btn">{t("apply")}</button>
                  { startDate &&
                  <button  onClick={clear} className="apply-top-btn clear-top-btn">{t("clear")}</button>}
                </div>
                </div>  
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <Tabs
                  defaultActiveKey={defaultTab}
                  id="uncontrolled-tab-example"
                  className="prediction-tab mb-3"
                  onSelect={handleSelect}>
                  <Tab eventKey="RECEIVABLE" title={t("received")} >
                   <div className="audit-request-box">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>{t("date")}</th>
                            <th>{t("trans_id")}</th>
                            <th>{t("order_id")}</th>
                            <th>{t("user")}</th>
                            <th>{t("order_amt")}</th>
                            <th>{t("admin_earning")}</th>
                            <th>{t("status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionData && transactionData.map((data, index) => {
                           return (
                              <tr>
                                <td><p>{moment(data?.transactionDate).format("DD MMM, YYYY")}</p></td>
                                <td> {data.transactionId}</td>
                                <td>{data.orderId ?
                                    <Link to={"/order-mangement/order-details/" + data.orderId} className="linksdata">#{data.orderId}</Link> : "N/A"}
                                </td>
                                <td>
                                  {data.userType == "ROLE_USER" ?
                                    <Link to={"/user-details/" + data.userId} className="linksdata">{data.userName}</Link> :
                                    <>
                                      {data.userType == "ROLE_DRIVER" ?
                                        <Link to={"/driver-details/" + data.userId} className="linksdata">{data.userName}</Link> :
                                        <Link to={"/cultivator-details/" + data.userId} className="linksdata">{data.userName}</Link>}
                                    </>}
                                </td>
                             
                                <td> ${formatToColombianCurrency(data.grossAmount)} </td>
                                <td> ${data?.platformFee?formatToColombianCurrency(data?.platformFee):0}</td>
                               <td><p>{data.status=="PENDING"?t("pending"):t("success")}</p></td>
                              </tr>)
                          })}
   {transactionData && transactionData.length == 0 &&
                          <tr>
                            <td colSpan={7}>
                              <div className="text-center">
                                <div className="no-data-found-section">
                                  <img src={require("../../assets/images/no-data.svg").default} alt="" />
                                  <h5><b>{t("no_data_found")}</b></h5>
                                </div>
                              </div>
                            </td>
                          </tr>}
                        </tbody>
                      </Table>


                      {transactionData && transactionData.length > 0 ?

                        <Pagination
                          activePage={page + 1}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                          prevPageText={"Prev"}
                          nextPageText={"Next"}
                        />
                        : null
                      }

                    </div>

                  </Tab>
                  <Tab eventKey="PAYABLE" title={t("paid")} >

                    <div className="audit-request-box">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>{t("date")}</th>
                            <th>{t("trans_id")}</th>
                        
                            <th>{t("user")}</th>
                            <th>{t("user_type")}</th>
                            <th>{t("gross_amt")}t</th>
                            <th>{t("platformfee")} </th>
                            <th>{t("paid_amt")}</th>


                            <th>{t("status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionData && transactionData.map((data, index) => {
                            // {console.log(data.chargeId)}

                            return (
                              <tr>
                                <td><p>{moment(data?.transactionDate).format("DD MMM, YYYY")}</p> </td>
                                <td>
                                  {data.transactionId
                                  }
                                </td>

                                <td>
                                  {data.userType == "ROLE_USER" ?
                                    <Link to={"/user-details/" + data.userId} className="linksdata">{data.userName}</Link> :
                                    <>
                                      {data.userType == "ROLE_DRIVER" ?
                                        <Link to={"/driver-details/" + data.userId} className="linksdata">{data.userName}</Link> :
                                        <Link to={"/cultivator-details/" + data.userId} className="linksdata">{data.userName}</Link>}
                                    </>}
                                </td>
                                <td>
                                  {data.userType == "ROLE_USER" ?
                                    "User" :
                                    <>
                                      {data.userType == "ROLE_DRIVER" ?
                                        "Driver" :
                                        "Cultivator"}
                                    </>}
                                </td>
                                <td>
                                  ${formatToColombianCurrency(data.grossAmount)}
                                </td>
                                <td>
                                  ${data?.platformFee?formatToColombianCurrency(data?.platformFee):0}
                                </td>
                                <td>
                                  ${data?.netAmount?formatToColombianCurrency(data?.netAmount):0}
                                </td>
                                <td>
                                  <p >{data.status=="PENDING"?t("pending"):t("success")}</p>
                                </td>
                              </tr>)
                          })}
{transactionData && transactionData.length == 0 &&
                          <tr>
                            <td colSpan={7}>
                              <div className="text-center">
                                <div className="no-data-found-section">
                                  <img src={require("../../assets/images/no-data.svg").default} alt="" />
                                  <h5><b>{t("no_data_found")}</b></h5>
                                </div>
                              </div>
                            </td>
                          </tr>}
                        </tbody>
                      </Table>


                      {transactionData && transactionData.length > 0 ?

                        <Pagination
                          activePage={page + 1}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItems}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                          prevPageText={"Prev"}
                          nextPageText={"Next"}
                        />
                        : null
                      }

                    </div>

                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );

};