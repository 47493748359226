import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { apiService } from "../../service/api.service";
import { useTranslation } from "react-i18next";
import "../../i18n";
import swal from "sweetalert";
const MultiSelectField = ({users,onSelectUsers,checkSelectedUser,checkSelectedUserData,setIsCheckObj,setIsCheck,isCheckRef}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleChange = (selectedOptions) => {
  console.log("====selectedOptions===",selectedOptions)
    setSelectedUsers(selectedOptions);
    setIsCheckObj(selectedOptions)
    const selectedIds = selectedOptions.map(option => option.value);
     isCheckRef.current=selectedIds
    // setIsCheck(selectedIds)
    const mergedArray = selectedIds.concat(checkSelectedUser);
    
    // setIsCheck(mergedArray)
    console.log("====selectedOptions====",selectedOptions)
    onSelectUsers(mergedArray); // Pass only the IDs to the parent component
    // onSelectUsers(selectedOptions.value);
  };
  useEffect(() => {
    console.log("====checkSelectedUserData=====",checkSelectedUserData)
    // handleChange(checkSelectedUserData)
    // Replace this with your actual data fetching logic
    setSelectedUsers(checkSelectedUserData)
    const allUsers = users.map(user => ({ value: user.id, label: user.firstName }));
    setOptions(allUsers);
    // onSelectUsers(checkSelectedUser); 
    
  }, [checkSelectedUserData]);

 
  // Transform user data to be compatible with react-select
 
  
  const getUser =async(search)=>{
    try {
      const response = await apiService.get_users(
        page,
        size,
        "ROLE_USER",
        search,
        ""
      );
      if (response?.status == 200) {
       
        let responseData = response.data.data.list;
        const allUsers = responseData.map(user => ({ value: user.id, label: user.firstName }));
        setOptions(allUsers);
        // setUserData(responseData);
     }
    } catch (error) {
      if (error?.response?.status == 401) {
        

      
      } else {
        if (error?.response?.status == 403) {
          // setLoader(false);
          swal({
            icon: "error",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
            button: "OK",
          }).then((value) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
         
          swal({
            icon: "error",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
            button: "OK",
          }).then((value) => {
            console.log("OK button clicked after error alert");
          });
        }
      }
    }
  }
const inputValChange = (val)=>{
  console.log("======options.length==",options.length)
  // if(options.length==0){
    getUser(val)
  // }
  console.log("======input==",val)

}
 
  return (
    <div>
       <label class="form-label"><b>{t("select_user")}</b></label>

      <Select
        isMulti
        options={options}
        value={selectedUsers}
         onInputChange={(e) => inputValChange(e)}
        loadingIndicator={true}
        onChange={handleChange}
        // onChange={(e) => console.log(`e`, e)}
        placeholder={t("search_user")}
      />
    </div>
  );
};

// Example usage
const MultiSelect = ({ userData,onUsersSelected,checkSelectedUser,checkSelectedUserData,setIsCheckObj,setIsCheck,isCheckRef}) => {
  const [users, setUsers] = useState([]);
  
  console.log("====checkSelectedUserData====",checkSelectedUserData)
  useEffect(() => {
    // Replace this with your actual data fetching logic
    setUsers(userData)
    //  handleSelectUsers(checkSelectedUser)
    
  }, []);
  const handleSelectUsers = (selectedUsers) => {
    console.log("====selectedUsers===ddddd=",selectedUsers)
    
    onUsersSelected(selectedUsers);
  };
  return (
    <div>
      
      <MultiSelectField users={userData} onSelectUsers={handleSelectUsers} checkSelectedUser={checkSelectedUser} checkSelectedUserData={checkSelectedUserData} setIsCheckObj={setIsCheckObj} setIsCheck={setIsCheck} isCheckRef={isCheckRef}/>
    </div>
  );
};

export default MultiSelect;
