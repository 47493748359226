import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Table, Row } from "react-bootstrap";

import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { baseUrl, getStatus,formatToColombianCurrency } from "../../config/config"
import { apiService } from "../../service/api.service";
import swal from 'sweetalert';

import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { ThreeDotSpinner } from "../loader";
import { useTranslation } from 'react-i18next';
import '../../i18n';

export const OrderDetails = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let props = useParams()
    const [loader, setLoader] = useState(false)
    const [orderData, setorderData] = useState()
    const [orderReviews, setorderReview] = useState(null)
    const [orderHis, setorderHis] = useState()
    const [orderStatus, setorderStatus] = useState()
    const [otp, setOtp] = useState()
    const [otpErr, setOtpErr] = useState()
    const [ratingStar, setratingStar] = useState({
        size: 30,
        value: 0,
        edit: false,
        isHalf: true,
        emptyIcon: <i className="far fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
    });
    // props.orderID
    useEffect(() => {
        getOrderDetail(props.orderId, false)
        // orderReview(props.orderId)
        // orderHistory(props.orderId)

    }, [])

    const getOrderDetail = (id, socket) => {
        if (!socket) {
            setLoader(true)
        }

        apiService.getOrderDetail(id).then((res) => {
            setorderData(res.data.data)
            setorderStatus(getStatus(res.data.data.order.status))
            console.log("order====", res.data.data)
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            if (error?.response?.status == 401) {
                setLoader(false);

                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                if (error?.response?.status == 403) {
                    setLoader(false)
                    swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                        (value) => {
                            localStorage.clear()
                            window.location.href = "/";
                        }
                    );
                }else{
                    setLoader(false)
                    swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                        (value) => {
                            console.log('OK button clicked after error alert');
                        }
                    );
                }
            }

            // console.log(err)
            console.log("contact us api error", error.response);
        });
    }

    const orderReview = (id) => {
        apiService.orderReview(id).then((res) => {
            setratingStar({
                ...ratingStar,
                value: res.data.data.cultivatorReview.rating,
            });
            setorderReview(res.data.data.cultivatorReview)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            //  errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const orderHistory = (id) => {
        apiService.orderHistory(id).then((res) => {
            console.log("orderHis====", res.data)
            setorderHis(res.data.data.list)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            //  errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }

    const acceptRejectOrder = (status) => {
        let params = {
            orderId: props.orderId,
            status: status
        }
        setLoader(true)
        apiService.acceptRejectOrder(params).then((res) => {
            swal("Success", "Order " + status + "ed !", "success")
            getOrderDetail(props.orderId, false)

            orderHistory(props.orderId)
            console.log("order====", res.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            // errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const searchDriver = () => {

        setLoader(true)
        apiService.searchDriver(props.orderId).then((res) => {
            //   swal("Success","Order "+status+"ed !","success")
            getOrderDetail(props.orderId, false)

            console.log("order====", res.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            // errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const orderPickup = () => {
        if (!otp) {
            setOtpErr("Please Enter Otp !")
            return
        }
        setLoader(true)
        apiService.orderPickup(props.orderId, otp).then((res) => {
            setOtp()
            setOtpErr()
            getOrderDetail(props.orderId, false)
            handleClose()
            setLoader(false)
        }).catch((err) => {
            setOtp()
            setOtpErr(err.response.data.message)
            setLoader(false)
            // errorHandling(err.response.status)
            console.log("contact us api error", err.response);
        });
    }



    const getTimeAgo = (dateTime) => {
        dateTime = moment.utc(dateTime).local()
        return moment(dateTime).fromNow();
    };





    return (<>
        <div className="dashboard-main-area order-details-section">
            {loader == true ? <ThreeDotSpinner /> : ""}
            <Container fluid>

                <div className="dashboard-area-heading management-heading">
                    <Row className="pb-4 mb-2 justify-content-center">
                        <Col md={11}>


                            <h2 className='subheading-under'>{t("order_detail")}</h2>
                            {/* <Row className="product-detail">
                  <Col md={12}> */}

                            <div className='project-management common-under-padding'>

                                <div className="stock-shadow">
                                    <div className="order-history-header">
                                        <p className="date-text order-detail-top-area">
                                            <div>
                                                <img
                                                className=""
                                                src={require("../../assets/images/date.svg").default}
                                                alt="img"
                                            />
                                        
                                                {moment(orderData?.order?.orderDate).format("DD MMM, YYYY")}
                                                </div>
                                            <div className="status-txt">
                                                {orderStatus == "order_cancelled" ?
                                                    <>
                                                        <span className="delivered pending"></span>
                                                        <h4>{orderStatus ? t(orderStatus) : ""}</h4></> :
                                                    <>
                                                        {orderStatus == "pending" ? <>
                                                            <span className="delivered pending"></span>
                                                            <h4>{orderStatus ? t(orderStatus) : ""}</h4></> :

                                                            <>

                                                                {orderStatus == "order_delivered" ? <>
                                                                    <span className="delivered"></span>
                                                                    <h4>{orderStatus ? t(orderStatus) : ""}</h4></> :
                                                                    <>
                                                                        {orderStatus == "driver_not_assigned" ?
                                                                            <> <span className="delivered not-assigned"></span>
                                                                                {orderStatus ? t(orderStatus) : ""}</>
                                                                            :
                                                                            <>
                                                                                {orderStatus == "driver_assigned" ?
                                                                                    <> <span className="delivered driver-assigned"></span>
                                                                                        {orderStatus ? t(orderStatus) : ""}</> :

                                                                                    <> <span className="delivered accepted"></span>
                                                                                        <h4>{orderStatus ? t(orderStatus) : ""}</h4> </>}


                                                                            </>


                                                                        }
                                                                    </>

                                                                }
                                                            </>}
                                                    </>}




                                            </div>
                                        </p>

                                        <div className="position-relative">
                                            {/* {orderStatus !== "Pending"  && */}
                                            {/* <Button className="export-btn" onClick={handleDownload}>Export PDF</Button>} */}

                                        </div>
                                    </div>
                                    <Row>
                                        <Col lg={9} className='mb-3'>
                                            <div className="customer-detailsl-box">
                                                <p className="customer-txt">{t("customer")}</p>
                                                <div className="customer-profile customer-profile-view">
                                                    <a href={"/user-details/" + orderData?.order?.customer?.id} target='_blank'>
                                                        <div className="customer-profile-img">
                                                            {orderData?.order?.customer?.imagePath == null ?
                                                                <img
                                                                    className=""
                                                                    src={require("../../assets/images/user-default.png")}
                                                                    alt="img"
                                                                /> :
                                                                <img
                                                                    className=""
                                                                    src={baseUrl + orderData?.order?.customer?.imagePath}
                                                                    alt="img"
                                                                />}
                                                            <div>

                                                                <p className="customer-name">{orderData?.order?.customer?.firstName + " " + orderData?.order?.customer?.lastName}</p>
                                                                <p className="customer-id">{t("customer_id")}#{orderData?.order?.customer?.id}</p>
                                                            </div>
                                                        </div>
                                                    </a>

                                                </div>
                                                <Row>
                                                    <Col md={4} lg={4}>
                                                        <div className="customer-info">
                                                            <p className="customer-id">{t("phone")}</p>
                                                            <p>{orderData?.order?.customer?.phone}</p>
                                                        </div>
                                                    </Col>

                                                    <Col md={4} lg={4}>
                                                        <div className="customer-info">
                                                            <p className="customer-id">{t("address")}</p>
                                                            <p>{orderData?.order.deliveryAddress?.address}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} lg={4}>
                                                        <div className="customer-info">
                                                            <p className="customer-id">{t("usedcredit")}</p>
                                                            <p>${formatToColombianCurrency(orderData?.order.creditsUsed)}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg={3} className='mb-3'>
                                            {orderData?.order?.driver &&
                                                <div className="customer-detailsl-box">
                                                    <div className='action-outer'>
                                                        <p className="customer-txt">{t("driver_info")}</p>
                                                      
                                                        {orderStatus !== "order_delivered" &&
                                                            // <a href ="https://www.google.com/maps" target='_blank'><Button className="green-btn radius-btn" >track order</Button></a>
                                                            <a href={`/order-mangement/track-order/${props.orderId}`} target='_blank'><Button className="green-btn radius-btn" >track order</Button></a>

                                                        }
                                                    </div>
                                                    <a href={"/driver-details/" + orderData?.order?.driver?.id} target='_blank'>
                                                        <div className="customer-profile customer-profile-delivery-box">
                                                            <div className="customer-profile-img">
                                                                <div>
                                                                    {orderData?.order?.driver?.imagePath != null ?
                                                                        <img
                                                                            className=""
                                                                            src={baseUrl + orderData?.order?.driver?.imagePath}
                                                                            alt="img"
                                                                        /> : <img
                                                                            className=""
                                                                            src={require("../../assets/images/user-default.png")}
                                                                            alt="img"
                                                                        />}

                                                                </div>
                                                                <div>
                                                                    <p className="customer-name">{orderData?.order?.driver?.firstName + " " + orderData?.order?.driver?.lastName}
                                                                    </p>
                                                                    <p className="customer-id">{orderData?.order?.driver?.phone}</p>
                                                                    <p className="customer-id mt-1">ID {orderData?.order?.driver?.id}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>}
                                        </Col>
                                    </Row>
                                    <div className='order-section'>
                                        <h5 className='latest-order-txt'>{t("order_list")}</h5>
                                        <Row className="pb-4 justify-content-center">
                                            <Col md={12} className="mt-2">
                                                <div className="audit-request-box">
                                                    <Table responsive="sm" className="UserListTable">

                                                        <thead>
                                                            <tr>
                                                                <th>{t("otder_id")}</th>
                                                                <th>{t("product_name")}</th>
                                                                <th>{t("packaging")}</th>
                                                                <th>{t("qty")}</th>
                                                                <th>{t("amount")}</th>
                                                                <th>{t("total_price")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderData && orderData.order.products.map((data, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>#{props.orderId}</td>
                                                                        <td>
                                                                            {/* <a href={'/product-detai/'+data.id} target='_blank'> */}
                                                                            <div className='product-outer-main'>
                                                                                {data.productImagePath != null &&
                                                                                    <span className='product-img'>
                                                                                        <img
                                                                                            className=""
                                                                                            src={baseUrl + data.productImagePath}
                                                                                            // src={require("../../assets/images/mango.png")}
                                                                                            alt="img"
                                                                                        />
                                                                                    </span>}
                                                                                {data.productName}
                                                                            </div>
                                                                            {/* </a> */}
                                                                        </td>
                                                                        <td>{data.packaging + " " + data.unit}  </td>
                                                                        <td>{data.productTotalQuantity} item </td>
                                                                        <td>${formatToColombianCurrency(data.productPrice)}</td>
                                                                        <td>${formatToColombianCurrency(data.productTotal)}</td>
                                                                    </tr>
                                                                )
                                                            })}



                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pb-4 justify-content-center">
                                            <Col md={4} className="mt-2">
                                                <div className="shipping-box">

                                                    <div className="text-right">
                                                        <h5 className="text-left">{t("customer")}:</h5><br></br>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("gross_amt")}:</p>
                                                            <p>${formatToColombianCurrency(orderData?.order?.grossAmount)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("delivery_fee")}:</p>
                                                            <p className="text-dark">{orderData?.order?.deliveryFee && "$"+ formatToColombianCurrency(orderData?.order?.deliveryFee)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("service_fee")}:</p>
                                                            <p className="text-dark">${orderData?.order?.serviceFee && formatToColombianCurrency(orderData?.order?.serviceFee)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("net_amt")}:</p>
                                                            <p className="text-dark">${orderData?.order?.totalAmount && formatToColombianCurrency(orderData?.order?.totalAmount)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("usedcredit")}:</p>
                                                            <p className="text-dark">-${formatToColombianCurrency(orderData?.order.creditsUsed)}</p>
                                                        </div>
                                                      
                                                        {/* <p className="payment-status">Payment Done</p> */}
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={4} className="mt-2">
                                                <div className="shipping-box">

                                                    <div className="text-right">
                                                        <h5 className="text-left">{t("cultivator")}:</h5><br></br>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("gross_payout")}:</p>
                                                            <p>${formatToColombianCurrency(orderData?.commission?.cultivatorGross)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("platform_fee")}:</p>
                                                            <p className="red-text">-${orderData?.commission?.cultivatorCommission && formatToColombianCurrency(orderData?.commission?.cultivatorCommission)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("net_payout")}:</p>
                                                            <p className="text-dark">${orderData?.commission?.cultivatorPayable && formatToColombianCurrency(orderData?.commission?.cultivatorPayable)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("payment_status")}:</p>
                                                            <p className="payment-status">{orderData?.commission?.cultivatorPaymentStatus}</p>
                                                        </div>
                                                        {/* <p className="payment-status">Payment Done</p> */}
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={4} className="mt-2">
                                                <div className="shipping-box">
                                                    <div className="text-right">
                                                        <h5 className="text-left">{t("driver")}:</h5><br></br>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("gross_payout")}:</p>
                                                            <p>${formatToColombianCurrency(orderData?.commission?.driverGross)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p>{t("platform_fee")}:</p>
                                                            <p className="red-text">-${orderData?.commission?.driverCommission && formatToColombianCurrency(orderData?.commission?.driverCommission)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("net_payout")}:</p>
                                                            <p className="text-dark">${orderData?.commission?.driverPayable && formatToColombianCurrency(orderData?.commission?.driverPayable)}</p>
                                                        </div>
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("payment_status")}:</p>
                                                            <p className="payment-status">{orderData?.commission?.driverPaymentStatus}</p>
                                                        </div>
                                                        <Col md={4} lg={4}>
                                                    
                                                    </Col>
                                                        {/* <p className="payment-status">Payment Done</p> */}
                                                    </div>

                                                </div>
                                                <div className="shipping-box-inner"></div>
                                                
                                                        <div className="shipping-box-inner">
                                                            <p className="text-dark">{t("admin_earning")}:</p>
                                                            <p>${orderData?.commission?.adminCommission ? formatToColombianCurrency(orderData?.commission?.adminCommission):0}</p>
                                                        </div>
                                            </Col>
                                            
                                        </Row>
                                    </div>

                                </div>










                            </div>



                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
        {/* success modal */}

    </>)
}

