export const validateService = {
  validateProduct,
  validateVC


}


function validateProduct(productFields,setproductFieldsErr,images){

    let formValidated = true;
    if (images.length == 0) {
        formValidated = false;
        setproductFieldsErr((obj) => {
             return {
             ...obj,
             name: "",
             description: "",
             images: "atleast_one_img",
             category: "",
    
             
             };
         });
 }else  if (productFields.name.trim() === "") {
  formValidated = false;
  setproductFieldsErr((obj) => {
       return {
       ...obj,
       name: "product_name_valid",
       description:"",
       images: "",
       category: "",
       
       };
   });
}else  if (productFields.description.trim() === "") {
  formValidated = false;
  setproductFieldsErr((obj) => {
       return {
       ...obj,
       name: "",
       description:"product_desc_valid",
       images: "",
       category: "",
       
       };
   });
}  else if (productFields.categoryId ==""){
    
     formValidated = false;
     setproductFieldsErr((obj) => {
      return {
        ...obj,
        name: "",
        description:"",
        images: "",
        category: "select_cat",
        
        };
     });
 } else if (productFields.unitId ==""){
    
  formValidated = false;
  setproductFieldsErr((obj) => {
   return {
     ...obj,
     name: "",
     description:"",
     images: "",
     category: "",
     packaging: "add_package",
     
     };
  });
}
 else if (productFields.unitId ==""){
    
  formValidated = false;
  setproductFieldsErr((obj) => {
   return {
     ...obj,
     name: "",
     description:"",
     images: "",
     category: "",
     unitId: "add_package",
     
     };
  });
}else if (productFields.price ==""){
    
  formValidated = false;
  setproductFieldsErr((obj) => {
   return {
     ...obj,
     name: "",
     description:"",
     images: "",
     category: "",
     unitId: "",
     price: "add_price",
     
     };
  });
}

else{
    formValidated = true;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        name: "",
        description:"",
        images: "",
        category: "",
      };
    });
  }
return formValidated
}
function validateVC (selectedUsers,amount,expiration,setErrors) {
  const newErrors = {};
console.log("====selectedUsers==",selectedUsers)
  if (!selectedUsers.length) {
    newErrors.selectedUsers = 'user_require';
  }

  // if (!message) {
  //   newErrors.message = 'msg_require';
  // } else if (message.length < 5) {
  //   console.log(message.length)
  //   console.log(message)
  //   newErrors.message = 'Message must be at least 5 characters long.';
  // }

  if (!amount) {
    newErrors.amount = 'amt_require';
  } else if (isNaN(amount) || amount <= 0) {
    newErrors.amount = 'Amount must be a positive number.';
  }

  if (!expiration) {
    newErrors.expiration = 'exp_require';
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};