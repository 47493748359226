import React, { useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { apiService } from "../../service/api.service";
import { validateService } from "../../service/validation.service";
import swal from "sweetalert";
import moment from "moment";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { baseUrl, allowedImageTypes ,defaultLanguage,formatToColombianCurrency} from "../../config/config";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const Products = () => {
  const { t } = useTranslation();
  let history = useNavigate();
  function PreoductDetail(id) {
    history("/product-detail/" + id);
  }
  const fileInputRef = useRef(null);
  const options = [{ value: "", label: "All" }];
  const [unitData, setunitData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addData, setaddData] = useState(false);
  const [type, setType] = useState(10);
  const [selectedOption, setSelectedOption] = useState();
 const handelCloseData = () => {
    setproductId();
    setaddData(false);
  };
  const [dataoptions, setdataoptios] = useState([]);
  const [totalItems, settotalItems] = useState();
  const [page, setPage] = useState(0);
  const [cat, setcat] = useState("");
  const [productId, setproductId] = useState();

  const [uploadedimages, setuploadedimages] = useState([]);
  const [imagesProduct, setimagesProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [productFields, setproductFields] = useState({
    name: "",
    categoryId: "",
    description: "",
    unitId: "",
    price: "",
    packaging: "",
  });

  const [productFieldsErr, setproductFieldsErr] = useState({
    name: "",
    category: "",
    description: "",
    images: "",
    unitId: "",
    price: "",
    packaging: "",
  });
  const [catlist, setcatlist] = useState([]);

  const handleImagesChange = (file) => {
    console.log("====file====", file, "====");
    let valid = true;
    const selectedImage = file;
    if (selectedImage) {
      // console.log(
      //   "===imagelength====",
      //   uploadedimages.length + images.length + 1
      // );
      if (uploadedimages.length + images.length + 1 > 5) {
        valid = false;
        setproductFieldsErr({
          ...productFieldsErr,
          images: "You can't upload more than 5 product images.",
        });
      } else if (allowedImageTypes.includes(selectedImage.type)) {
        valid = true;
        const reader = new FileReader();

        reader.onload = () => {
          setImages((obj) => {
            // console.log(reader.result)
            return [...obj, reader.result];
          });
        };
        reader.readAsDataURL(selectedImage);
      } else {
        valid = false;
        setproductFieldsErr({
          ...productFields,
          images: "Invalid file type. Please select a JPEG or PNG image.",
        });
      }
    }
    return valid;
  };

  const handleFileChange = (e) => {
    // console.log(files)
    const files = e.target.files;
    const imageFiles = Array.from(files);
    if (imageFiles.length > 5) {
      // filesValidated = false;

      setproductFieldsErr({
        ...productFields,
        images: "You can't upload more than 5 files.",
      });
      return false;
    } else {
      {
        imageFiles.map((file, i) => {
          console.log("====file====", file);
          let valid = handleImagesChange(file);

          if (valid) {
            // console.log("=====file====",file)
            setimagesProduct((obj) => {
              return [...obj, file];
            });
          }
          // console.log("====imagesProduct===", imagesProduct);
          // imagedata.push(file)
        });
      }
    }
    fileInputRef.current.value = "";
  
  };

  const removeImageAtIndex = (indexToRemove) => {
    const updatedImages = imagesProduct.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedshowImages = images.filter(
      (_, index) => index !== indexToRemove
    );
    setimagesProduct(updatedImages);
    setImages(updatedshowImages);
  };
  const delete_product_image = (id, indexToRemove) => {
    apiService
      .deleteImage(id)
      .then((res) => {
        const updatedImages = uploadedimages.filter(
          (_, index) => index !== indexToRemove
        );
        setuploadedimages(updatedImages);
      })
      .catch((err) => {
        setLoader(false);
        //   Swal.fire("Error", "Something went wrong.", "error");
        //  console.log("contact us api error", err.response.data.message);
      });
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">{t("edit")}</p>
    </Tooltip>
  );
  const tooltipview = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">{t("view")}</p>
    </Tooltip>
  );

  useEffect(() => {
    GetCategoryList();
    get_products(0, 10, cat);
    getUnitList();
  }, []);

  const getUnitList = () => {
    apiService
      .GetUnitList()
      .then((res) => {
        // console.log("===unit====", res.data.data.list);
        let unitsData = [];
        res.data.data.list.map((data) => {
          unitsData.push({ value: data.id, label: data.unit });
        });
        setunitData(unitsData);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(err)
        console.log("contact us api error", err.response);
      });
  };

  function handlePageChange(pageNumber) {
    setPage(pageNumber - 1);
    get_products(pageNumber - 1, type, cat);
  }
  function handleCatChange(catid) {
    if (catid != cat) {
      setcat(catid);
      get_products(page, type, catid);
    }
  }
  async function get_products(page, type, catid) {
    setLoader(true);
    try {
      const response = await apiService.get_products(page, type, catid);
      if (response.status == 200) {
        console.log(response.data);
        setdataoptios(response.data.data.list);
        settotalItems(response.data.data.total);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      if (error?.response?.status == 401) {
        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          if (error?.response?.status == 403) {
            setLoader(false)
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                (value) => {
                    localStorage.clear()
                    window.location.href = "/";
                }
            );
        }else{
            setLoader(false)
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                (value) => {
                    console.log('OK button clicked after error alert');
                }
            );
        }
        }
      } else {
      }
    }
  }
 
  function resetFields() {
    setproductFields({
      name: "",
      categoryId: "",
      description: "",
      unitId: "",
      price: "",
      packaging: "",
    });
    setproductFieldsErr({
      name: "",
      category: "",
      description: "",
      images: "",
    });
    setImages([]);
    setimagesProduct([]);
    setuploadedimages([])
    
  }

  async function GetCategoryList() {
    try {
      setLoader(true);

      const response = await apiService.GetCategoryList();
      console.log("category...", response);
      if (response?.status == 200) {
        setcatlist(response?.data?.data.list);
        setLoader(false);
      }
    } catch (error) {
      console.log("????????", error);
      if (error?.response?.status == 400) {
        setLoader(false);
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      } else if (error?.response?.status == 401) {
        setLoader(false);
        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(
            () => {
              localStorage.clear();
              window.location.href = "/";
              console.log("OK button clicked after error alert");
            }
          );
        }
      } else {
        setLoader(false);
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  async function addproduct() {
    var message;
    let formValidated = true;
    if (productFields.id) {
      if(uploadedimages.length==0){
        formValidated = validateService.validateProduct(
          productFields,
          setproductFieldsErr,
          imagesProduct
        );
      }else{
        formValidated = validateService.validateProduct(
          productFields,
          setproductFieldsErr,
          uploadedimages
        );
      }
     
      message = t("update_success");
    } else {
      console.log("==productFields===", productFields);
      formValidated = validateService.validateProduct(
        productFields,
        setproductFieldsErr,
        imagesProduct
      );

      message = t("product_added");
    }

    if (formValidated) {
      setLoader(true);
      try {
        var response;
        response = await apiService.addproduct(productFields, imagesProduct);
        if (response.status == 200 || response.status == 201) {
          resetFields();
          get_products(page, type, cat);
          setLoader(false);
          handelCloseData();

          swal(t("success"), message, "success").then(() => {});
        }
      } catch (error) {
        console.log("=======>>>>>", error);
        // if(error.response.data.message=="Returned error: insufficient funds for gas * price + value"){
        //   swal("Error","Insufficient funds for gas * price.Please add funds to your wallet","error")
        //   handelCloseData()
        // }
        setLoader(false);
        if (error?.response?.status == 401) {
          swal({ text: "Unauthorized", button: "OK" }).then(() => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
        }
      }
    }
  }

  function handleSelect(key) {
    if (type != key) {
      setType(key);
      get_products(0, key, cat);
    }
  }

  function addDatapop() {
    resetFields();
    setaddData(true);
  }
  const tooltiSuspended = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">{t("disable")}</p>
    </Tooltip>
  );
  const tooltiActive = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">{t("enable")}</p>
    </Tooltip>
  );
  async function productStatus(id) {
    setLoader(true);
    try {
      // const params = show ? { reason: deactivatedReason } : {}

      // const response=await distrixService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await apiService.statusChange(id);
      if (response.status == 200) {
        get_products(page, type, cat);
        setLoader(false);
        swal(t("success"), response?.data?.message, "success").then(() => {});
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 401) {
        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(
            () => {
              localStorage.clear();
              window.location.href = "/";
              console.log("OK button clicked after error alert");
            }
          );
        }
      } else {
      }
    }
  }
  const EditDetailData = async (id) => {
    setproductId(id)
    setImages([]);
    setimagesProduct([]);
    setuploadedimages([])
    productDetail(id);
    getProductImages(id);
    setaddData(true);
  };
  async function productDetail(id) {
    setLoader(true);
    try {
      const response = await apiService.get_productDetail(id);
      if (response?.status == 200) {
        console.log("====unit==",response?.data.data.resource)
        setLoader(false);
        // setData(response?.data);
        setproductFields(response?.data.data.resource);
        setproductFields((prevObj) => {
          return {
            ...prevObj,
            categoryId: response?.data.data.resource.category.id,
          };
        });
        setSelectedOption(response?.data.data.resource.unit.unit)
        setproductFields((prevObj) => {
          return {
            ...prevObj,
            unitId: response?.data.data.resource.unit.id,
          };
        });
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }
  const getProductImages = async (id) => {
    const response = await apiService.get_productImages(id);
    if (response?.status == 200) {
      let imgdata = [];
      {
        console.log(response.data);
        response.data.data.list.map((img, index) => {
          imgdata.push({ image: baseUrl + img.path, id: img.id });
        });
        console.log("===imgdata==", imgdata);
        setuploadedimages(imgdata);
        // setImages(imgdata);
      }
    }
  };
  const handleKeyDownnumber = (event) => {
    console.log("=====event====", event.which);
    if (event.which === 32) {
        event.preventDefault(); // Prevent space key action
    }
    if (event.which === 189) {
        event.preventDefault(); // Prevent space key action
    }
    if (event.key === "Enter" || event.which === 13) {
        event.preventDefault(); // Prevent the default behavior (page refresh)
        // Handle your logic here
    }
};
  return (
    <>
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">
                      {t("product_management")}
                    </h2>
                  </div>
                  <div className="heading-top-area-right">
                  {localStorage.getItem("lang")==defaultLanguage &&
                    <div className="export-csv-btn add-btn-area me-2">
                      <Button
                        type="button"
                        variant="unset"
                        onClick={() => addDatapop()}
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i> {t("add")}
                      </Button>
                    </div>
                    }
                    <div className="filter-top-area">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mb-0">{t("filter")}:</Form.Label>
                        <div className="dropdown-icon-area">
                <select
                  className="form-control "
                  onChange={(e) => handleCatChange(e.target.value)}
                  value={cat}
                >
                  <option value="">{t("please_select")}
                    </option>
                  {/* <option value={2}>2 Min</option> */}

                  {catlist.map((cat, i) => (
                    <option key={i} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
                        {/* <Select
                          options={options}
                          placeholder="All"
                          onChange={(e) => handleCatChange(e.value)}
                        /> */}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className="availabilit-section">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>{t("sr_no")}</th>
                        {/* <th>Image</th> */}
                        <th>{t("product_name")}</th>
                       
                        <th>{t("category")}</th>
                        <th>{t("packaging")}</th>
                        <th>{t("unit")}</th>
                        <th>{t("price_per")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataoptions && dataoptions?.length > 0 ? (
                        dataoptions.map((data, index) => {
                          // console.log("=====data====", data);
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {/* <td><img src={baseUrl+data.path} className="product-img"/></td> */}

                              <td className="question-area">
                                {data?.name ? <>{data?.name}</> : <>-</>}
                                {/* </a> */}
                              </td>
                            

                              <td className="email-section">
                                {data?.category ? <>{data.category}</> : <>-</>}
                              </td>
                              <td>
                              
                                  {data.packaging}
                         
                              </td>
                              <td>
                              
                                  {data.unit}
                              
                              </td>
                              <td>
                              
                                  ${formatToColombianCurrency(data.price)}
                            
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="view-eye-area me-2">
                                    <div className="d-flex align-items-center">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltip}
                                      >
                                        <div
                                          className="edit-pencil"
                                          onClick={() =>
                                            EditDetailData(data.id)
                                          }
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            style={{ cursor: "pointer" }}
                                          ></i>
                                        </div>
                                      </OverlayTrigger>{" "}
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipview}
                                      >
                                        <div className="edit-pencil">
                                          <i
                                            class="fa fa-eye"
                                            onClick={() =>
                                              PreoductDetail(data.id)
                                            }
                                            style={{ cursor: "pointer" }}
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </OverlayTrigger>{" "}
                                    </div>
                                  </div>

                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      data?.enabled
                                        ? tooltiActive
                                        : tooltiSuspended
                                    }
                                  >
                                    <Form.Check
                                      type="switch"
                                      id={"custom-switch" + data.id}
                                      checked={data?.enabled}
                                      onClick={(e) =>
                                        swal({
                                          title: t("confirm"),
                                          text: `${ data?.enabled ?t("product_disabled")
                                              : t("product_enable")
                                          } `,
                                          icon: "warning",
                                          buttons: [t("no"), t("yes")],
                                        }).then((value) => {
                                          if (value) {
                                            productStatus(data?.id);
                                          } else {
                                            console.log(
                                              "NO button clicked or popup closed"
                                            );
                                          }
                                        })
                                      }
                                    />
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="text-center">
                              <div className="no-data-found-section">
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt=""
                                />
                                <h5>
                                  <b>{t("no_data_found")}</b>
                                </h5>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <> </>
                </div>

                {dataoptions.length > 0 && totalItems > 10 ? (
                  <Pagination
                    activePage={page + 1}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    prevPageText={"Prev"}
                    nextPageText={"Next"}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal
        className="add-pediction-area"
        show={addData}
        onHide={handelCloseData}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {productId ? t("update_pro") : t("add_pro")} 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3">
          <Form>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-2" controlId="formBasicEmail">
                  <Form.Label>
                    {t("upload_img")} <span>*</span>{" "}
                  </Form.Label>
                  <div className="uploaded-area">
                    {productId && images && images.length<5?
                    <div className="upload-image-area">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      <Form.Control type="text" />

                      <Form.Control
                        type="file"
                        multiple={true}
                        className="upload-item"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        accept="image/*"
                      />
                    </div>:  
                    <>
                        { imagesProduct && imagesProduct.length<5?
                    <div className="upload-image-area">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      <Form.Control type="text" />

                      <Form.Control
                        type="file"
                        multiple={true}
                        className="upload-item"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        accept="image/*"
                      />
                    </div>:null}
                    </>
                    }

                    {images.length > 0 &&
                      images.map((image, index) => (
                        <div className="upload-image-area-right">
                          {console.log("=====", image)}
                          <img
                            key={index}
                            src={image}
                            alt={`Preview ${index}`}
                            className="image-preview"
                          />
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            onClick={() => removeImageAtIndex(index)}
                          ></i>
                        </div>
                      ))}

                    {uploadedimages.length > 0 &&
                      uploadedimages.map((image, index) => (
                        <div className="upload-image-area-right">
                          {console.log("=====image====", image)}
                          <img
                            key={index}
                            src={image.image}
                            alt={`Preview ${index}`}
                            className="image-preview"
                          />
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            onClick={() =>
                              delete_product_image(image.id, index)
                            }
                          ></i>
                        </div>
                      ))}
                  </div>
                  <span className="text-danger">{t(productFieldsErr.images)}</span>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>
               {t("product_name")} <span>*</span>
              </Form.Label>
              <input
                maxLength={100}
                className="form-control "
                type="textarea"
                value={productFields.name}
                onChange={(e) => {
                  setproductFields((prevObj) => {
                    return {
                      ...prevObj,
                      name: e.target.value,
                    };
                  });
                }}
                placeholder={t("enter_product")}
              />
              <span className="text-danger">{t(productFieldsErr.name)}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
              {t("product_desc")} <span>*</span>
              </Form.Label>
              <textarea
                maxLength={1000}
                className="form-control "
                type="textarea"
                placeholder={t("enter_desc")}
                value={productFields.description}
                onChange={(e) => {
                  setproductFields((prevObj) => {
                    return {
                      ...prevObj,
                      description: e.target.value,
                    };
                  });
                }}
              />
              <span className="text-danger">
                {t(productFieldsErr.description)}
              </span>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {t("category")} <span>*</span>
              </Form.Label>
              <div className="dropdown-icon-area">
                <select
                  className="form-control "
                  onChange={(e) => {
                    setproductFields((prevObj) => {
                      return {
                        ...prevObj,
                        categoryId: e.target.value,
                      };
                    });
                  }}
                  value={productFields.categoryId}
                >
                  <option value="">{t("please_select")}</option>
                  {/* <option value={2}>2 Min</option> */}

                  {catlist.map((cat, i) => (
                    <option key={i} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>

              <span className="text-danger">{t(productFieldsErr.category)}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row>
                <Col md={6}>
                  <Form.Label>
                  {t("packaging")} <span>*</span>
                  </Form.Label>
                  <Form.Control
                    class="quantity-input" onKeyDown={handleKeyDownnumber} maxLength={8}
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    value={productFields.packaging}
                    onChange={(e) =>
                      setproductFields((prevObj) => {
                        return {
                          ...prevObj,
                          packaging: e.target.value,
                        };
                      })
                    }
                  />
                  <span className="text-danger">
                    {t(productFieldsErr.packaging)}
                  </span>
                </Col>
                <Col md={6}>
                  <Form.Label>
                  {t("unit")} <span>*</span>
                  </Form.Label>
                  <div className="dropdown-icon-area">
                <select
                  className="form-control "
                  onChange={(e) => {
                    setproductFields((prevObj) => {
                      return {
                        ...prevObj,
                        unitId: e.target.value,
                      };
                    });
                  }}
                  value={productFields.unitId}
                >
                  <option value="">{t("please_select")}</option>
                  {/* <option value={2}>2 Min</option> */}

                  {unitData.map((data, i) => (
                    <option key={i} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </select>

                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
           
                  <span className="text-danger">{t(productFieldsErr.unitId)}</span>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
              {t("price_per")} ($)<span>*</span>
                         
                         
              </Form.Label>
              <Form.Control class="quantity-input" onKeyDown={handleKeyDownnumber} maxLength={8}
                                    onWheel={() => document.activeElement.blur()}
                                    type="number"
                                    placeholder={t("enter_price")}
                                    value={productFields.price}
                                    onChange={(e) =>
                                      setproductFields((prevObj) => {
                                        return {
                                          ...prevObj,
                                          price: e.target.value,
                                        };
                                      })
                                    }
                                />
      
              <span className="text-danger">{t(productFieldsErr.price)}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addproduct()}>{t("save")}</Button>
          <Button onClick={handelCloseData} className="close-btn-bottom">
          {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
