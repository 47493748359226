import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../loader";
import { useContext } from "react";
import { defaultLanguage } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { apiService } from "../../service/api.service";
import { useTranslation } from 'react-i18next';
import '../../i18n';

export const Unit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [Unit, setUnit] = useState([]);
  const [Unit_name, setUnit_name] = useState("");
  const [edit, setEdit] = useState(false);
  const [editUnit_name, setEditUnit_name] = useState("");
  const [editUnit_id, setEditUnit_id] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState()
  const [catErr, setcatErr] = useState("")

  const handleNameChange = (event) => {
    if (edit == true) {
      setEditUnit_name(event.target.value)
    } else {
      setUnit_name(event.target.value);
    }
  };



  useEffect(() => {
    UnitDetail(page, search)
  }, [])

  async function UnitDetail(page, search) {
    try {
      setLoader(true)
      const params = {
        page: page,
        search: search
      }
      const response = await apiService.GetUnitList(params)
      console.log("Unit...", response)
      if (response?.status == 200) {
        setUnit(response?.data?.data.list)
        setLoader(false)
        setTotalCount(response?.data?.total_count)
      }

    } catch (error) {
      console.log("????????", error)
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload()
        } else {
          swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        }


        setLoader(false)

      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  async function UnitAdd(data) {
    if (data == "") {
      setcatErr(t("unit_valid"))

    } else {
      setcatErr("")

      try {
        setLoader(true)

        const params = { unit: data }
        const response = await apiService.AddUnit(params)

        if (response?.status == 201) {
          setLoader(false)
          setIsModalOpen(false)
          swal(t("success"), t("unit_updated"), "success").then((value) => {
            if (value) {
              setUnit_name("")
              setIsModalOpen(false)
              UnitDetail(page, search)
              console.log('OK button clicked after error alert');
            }
          });
        } else if (response?.status == 200) {
          setLoader(false)
          swal(t("success"), t("unit_added"), "success").then((value) => {
            if (value) {
              setUnit_name("")
              setIsModalOpen(false)
              UnitDetail(page, search)
              console.log('OK button clicked after error alert');
            }
          });
        }
      } catch (error) {
        if (error?.response?.status == 400) {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
            (value) => {
              console.log('OK button clicked after error alert');
            }
          );
        } else if (error?.response?.status == 401) {
          setLoader(false)
          swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        } else {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
            (value) => {
              console.log('OK button clicked after error alert');
            }
          );
        }
      }
    }
  }


  async function UnitEdit(data, id) {
    try {
      if (data.trim() == "") {
        setcatErr(t("unit_valid"))
  
      } else{
        
      
        setcatErr("")
      setLoader(true)

      const params = { unit: data, id: id }
      const response = await apiService.AddUnit(params)
      if (response?.status == 200 || response?.status == 201) {
        setLoader(false)
        swal(t("success"), t("unit_updated"), "success").then((value) => {
          if (value) {
            UnitDetail(page, search)
            setIsModalOpen(false)
            setEdit(false)
            console.log('OK button clicked after error alert');
          }
        });
      }
    }
    } catch (error) {
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          }
        );
      }
      
    }
  
  }


  async function UnitDelete(id) {
    try {
      setLoader(true)
      const response = await apiService.DeleteUnit(id)
      if (response?.status == 200) {
        setLoader(false)
        swal(t("success"), t("unit_deleted"), "success").then((value) => {
          if (value) {
            UnitDetail(1, search)
            setPage(1)
            console.log('OK button clicked after error alert');
          }
        });

      } else if (response?.status == 204) {
        setLoader(false)
        swal(t("success"), t("unit_deleted"), "success").then((value) => {
          if (value) {
            UnitDetail(1, search)
            setPage(1)
            console.log('OK button clicked after error alert');
          }
        });
      }
    } catch (error) {
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );

      } else if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  function subUnit(id) {
    navigate(`/subUnit/${id}`);
  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber);
    UnitDetail(pageNumber, search)
  }

  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    UnitDetail(1, search)
  }

  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="heading-top-area mb-3">
                <div>
                  <h2 className="main-heading mt-0">{t("unit_management")}</h2>
                </div>
                <div className="heading-top-area-right">
                  {/* <div className="search-area me-2">
                    <label>Search</label>
                    <form
                      autoComplete="off"
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        className="form-control inner-input"
                        onChange={(e) => handleUpdateSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        onClick={() => {
                          if (search.length > 0) {
                            UnitDetail(page, search)
                          }
                        }
                        }
                      ></i>
                      <i className="fa fa-close d-none" aria-hidden="true"></i>
                    </form>
                  </div>   */}
                  {/* <div className="add-btn
                  ">
                    <Button onClick={openModal} className="UnitBtn">Add Unit</Button>
                  </div> */}
                  {/* <div className="export-csv-btn">
                    <Button type="button" variant="unset">Export CSV</Button>
                  </div> */}
                </div>
              </div>
              <Row>
                <Col md={8}>
                  <div className="audit-request-box categories-boxes">
                    <>
                      {loader == true ? <ThreeDotSpinner /> : ""}
                      <Row className="pb-4 justify-content-center">
                        <Col md={12} lg={12}>
                          <div className="availabilit-section">
                            <Table responsive="sm" className="UserListTable">
                              <thead>
                                <tr>
                                  <th>{t("sr_no")}</th>
                                  <th>{t("name")}</th>
                                  {/* <th>{t("translation_key")}</th> */}
                                  <th>{t("action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Unit && Unit.length > 0 ? Unit.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{(page - 1) * 10 + (index + 1)}</td>
                                      <td>{data.unit}</td>
                                      {/* <td>{data.translationKey}</td> */}
                                      {/* <td style={{ cursor: "pointer" }}><i onClick={() => subUnit(data?.id)} class="fa fa-eye view-eye" aria-hidden="true" ></i></td> */}
                                      <td><Button className="UnitBtn cat_Edit categoryBtn " onClick={(e) =>
                                        swal({
                                          title: t("confirm"),
                                          text: t("unit_confirm"),
                                          icon: "warning",
                                          buttons: [t("no"), t("yes")],
                                        }).then((value) => {
                                          console.log("value--->", value)
                                          if (value) {
                                            setIsModalOpen(true);
                                            setEdit(true);
                                            setEditUnit_name(data?.unit);
                                            setEditUnit_id(data?.id)

                                          } else {
                                            console.log(
                                              "NO button clicked or popup closed"
                                            );
                                          }
                                        })

                                      }> {t("edit")}</Button><Button className="categoryBtn cat_Delete" onClick={(e) =>
                                        swal({
                                          title: t("confirm"),
                                          text: t("unit_delete"),
                                          icon: "warning",
                                          buttons: [t("no"), t("yes")],
                                        }).then((value) => {
                                          console.log("value--->", value)
                                          if (value) {
                                            UnitDelete(data?.id);

                                          } else {
                                            console.log(
                                              "NO button clicked or popup closed"
                                            );
                                          }
                                        })


                                      }>{t("delete")}</Button></td>
                                    </tr>
                                  )
                                }) : <tr>
                                  <td colSpan={3}>
                                    <div className="text-center">
                                      <div className="no-data-found-section">
                                        <img
                                          src={
                                            require("../../assets/images/no-data.svg").default
                                          }
                                          alt="da"
                                        />
                                        <h5>{t("no_data_found")}</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                }

                              </tbody>
                            </Table>
                            {Unit && Unit.length > 0 && totalCount > 10 ?
                              <Pagination
                                activePage={page}
                                itemsCountPerPage={10}
                                totalItemsCount={totalCount}
                                pageRangeDisplayed={5}
                                onChange={(e) => handlePageChange(e)}
                                prevPageText={"Prev"}
                                nextPageText={"Next"}
                              />
                              : null
                            }


                          </div>
                        </Col>
                      </Row>
                    </>
                  </div>
                </Col>
                <Col md={4}>
                {edit  && localStorage.getItem("lang")!=defaultLanguage?
                  <div className="categories-add-box">
                  <h3>{t("add_unit")}</h3>
                  <input
                    type="text"
                    value={edit == false ? Unit_name : editUnit_name}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleNameChange}
                    className="form-control"
                    placeholder={t("enter_unit")}
                  />
                  <p className="errors">{catErr}</p>

                  <Button variant="primary" className="add-btns" onClick={() => {
                    if (edit == false) {
                      UnitAdd(Unit_name)
                    } else {
                      UnitEdit(editUnit_name, editUnit_id)
                    }
                  }}>
                    {t("add")}
                    
                  </Button>
                </div>:
                
                <>
                  {localStorage.getItem("lang")==defaultLanguage&&
                  <div className="categories-add-box">
                    <h3>{t("add_unit")}</h3>
                    <input
                      type="text"
                      value={edit == false ? Unit_name : editUnit_name}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.code === "Space") {
                          e.preventDefault();
                        }
                      }}
                      onChange={handleNameChange}
                      className="form-control"
                      placeholder={t("enter_unit")}
                    />
                    <p className="errors">{catErr}</p>

                    <Button variant="primary" className="add-btns" onClick={() => {
                      if (edit == false) {
                        UnitAdd(Unit_name)
                      } else {
                        UnitEdit(editUnit_name, editUnit_id)
                      }
                    }}>
                      {t("add")}
                      
                    </Button>
                  </div>}

                  </>}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <ToastContainer position="top-right" autoClose={3000} />
        {/* <Modal show={isModalOpen}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter Unit Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setIsModalOpen(false); setUnit_name(""); setEditUnit_name("") }}>
              Cancel
            </Button>

          </Modal.Footer>
        </Modal> */}
      </div>
    </>
  );
};
