import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import { baseUrl, dateFormate } from "../../config/config";
import { apiService } from "../../service/api.service";
import { useNavigate, Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from 'react-i18next';
import '../../i18n';


export const UserDetails = () => {
  const { t } = useTranslation();
  let props = useParams()
  const [loader, setLoader] = useState(false);
  const [profileData, setprofileData] = useState(false);
  localStorage.setItem('previousPath', window.location.pathname);
  useEffect(() => {
    get_usersDetail()
  }, [])
  const previousUrl = document.referrer;
  async function get_usersDetail() {
    setLoader(true)
    try {

      const response = await apiService.get_user_detail(props.id)
      if (response?.status == 200) {
        let responseData = response.data.data
        setprofileData(response.data.data.resource)
        
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        if(error?.response?.data?.token){
           localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload()
        }else{
          swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        }
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }
  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className='dashboard-area-heading management-heading'>
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">{t("user_detail")}</h2>
                  </div>
                </div>
              </Col>
            </Row>
            {/*  */}
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className='user-details-section'>
                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <div className='user-img'>
                         
                            <img src={ profileData?.imagePath?baseUrl+profileData?.imagePath:require("../../assets/images/Portrait_Placeholder.png")} alt="" className='user-img' />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("full_name")}</p>
                            {profileData?.role=="ROLE_CULTIVATOR" ?
                            <h5>{profileData?.
                            name?profileData?.name:"N/A"}</h5>:

                            <h5>{profileData?.
                              firstName?profileData?.firstName+" "+profileData?.lastName:"N/A"}</h5>}
                          </div>
                        </Col>
                        {profileData?.role!=="ROLE_USER" &&
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("email_address")}</p>
                            <h5>{profileData?.email?profileData?.email:"N/A"}</h5>
                          </div>
                        </Col>}
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("phone_number")}</p>
                            <h5>{profileData?.phone? profileData?.phone:"N/A"}</h5>
                          </div>
                        </Col>
                       
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("user_type")}</p>
                            <h5>{t("user")}</h5>
                          </div>
                        </Col>
                       
                      
                        {profileData.reason &&
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("reject_reason")}</p>
                            <h5>{profileData?.reason?profileData?.reason:"N/A"}</h5>
                          </div>
                        </Col>}
                      </Row>
                    </Col>
                    <Col md={4}>
                    {profileData?.role=="ROLE_CULTIVATOR" && profileData?.cultivator?.documentPath &&
                      <div className='document-information '>
                        <h4>Uploaded document</h4>
                        
                        <div className='uploaded-files mt-3'>
                          <img src={require("../../assets/images/file.svg").default} alt="" />
                          <a href={baseUrl+profileData?.cultivator?.documentPath} target="_blank"><p>Preview Doc</p></a>
                        </div>
                      </div>}
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
