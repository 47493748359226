import React from 'react';
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const polygonCoords = [
  { lat: -3.742, lng: -38.529 },
  { lat: -3.742, lng: -38.519 },
  { lat: -3.748, lng: -38.519 },
  { lat: -3.748, lng: -38.529 },
  { lat: -3.742, lng: -38.529 },
];

export const GeofenceMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA8FESApdFqas53CJfQQ1CP28PsKTPB1tQ',
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={14}
    >
      <Polygon
        path={polygonCoords}
        options={{
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
        }}
      />
    </GoogleMap>
  ) : <></>;
};


