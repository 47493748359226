import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Tab,
  Tabs,
  FloatingLabel,
} from "react-bootstrap";
import { formatToColombianCurrency } from "../../config/config";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { ThreeDotSpinner } from "../loader";
// import { useNavigate, Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
// import copy from "copy-to-clipboard";
import { apiService } from "../../service/api.service";
import { validateService } from "../../service/validation.service";
import MultiSelect from "./MultiSelect";
// import userImg from "../../assets/images/byDefaultUser.png"
// import { MyContext,apiUrl } from "../../config/config";
// import { useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

export const UsersList = () => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  <Tooltip id="tooltip">{t("view")}</Tooltip>;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [virtualCredits, setvirtualCredits] = useState();
  const [expiryDate, setexpiryDate] = useState();
  const [description, setdescription] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const isCheckRef = useRef([]);
  const [IsCheckObj, setIsCheckObj] = useState([]);
  // const { filterType, setFilterType } = useContext(MyContext)
  const [userData, setUserData] = useState([]);
  const [alluserData, setalluserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setErrors({})
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const [searchValue, setSearchValue] = useState("");

  const [statusEnabled, setstatusEnabled] = useState("");

  const [deactivatedReasonShow, setDeactivatedReasonShow] = useState("");
  let defaultRole = "ROLE_CULTIVATOR";
  const previousUrl = localStorage.getItem("previousPath");
  if (previousUrl.split("/")[1]) {
    if (previousUrl.split("/")[1] == "cultivator-details") {
      defaultRole = "ROLE_CULTIVATOR";
      // setrole("ROLE_CULTIVATOR")
    }
    if (previousUrl.split("/")[1] == "driver-details") {
      defaultRole = "ROLE_DRIVER";
      // setrole("ROLE_USER")
    }
    if (previousUrl.split("/")[1] == "user-details") {
      defaultRole = "ROLE_USER";
      // setrole("ROLE_USER")
    }
  }
  const [role, setrole] = useState(defaultRole);

  const [calldata, setcallData] = useState();
  const [limit, setlimit] = useState(10);

  const [userId, setUserId] = useState();

  const [showReason, setShowReason] = useState(false);

  const handleCloseReason = () => {
    setShowReason(false);
  };
  const handleShowReason = (reason) => {
    setDeactivatedReasonShow(reason);
    setShowReason(true);
  };
  const [copied, setcopied] = useState(false);

  function handleSelect(key) {
    if (role != key) {
      setSearchValue("");
      setrole(key);
      setPage(0);
      get_users(0, size, key, "", statusEnabled);
    }
  }

  // console.log("=====previousUrl====",previousUrl.split("/")[1])
  useEffect(() => {
    get_users(page, size, role, searchValue, statusEnabled);
    // get_allusers()
  }, []);

  const tooltiptoggle = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">{t("change_status")}</p>
    </Tooltip>
  );
  const tooltiSuspended = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0"> {t("suspend")}</p>
    </Tooltip>
  );
  const tooltiActive = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0"> {t("active")}</p>
    </Tooltip>
  );
  const setcopytext = () => {
    setTimeout(() => {
      setcopied(false);
    }, "1000");
  };
  const tooltip = <Tooltip id="tooltip">{t("view")}</Tooltip>;
  async function get_users(page, size, role, searchValue, statusEnabl) {
    setLoader(true);
    try {
      const response = await apiService.get_users(
        page,
        size,
        role,
        searchValue,
        statusEnabl
      );
      if (response?.status == 200) {
        localStorage.setItem("previousPath", "/users");
        let responseData = response.data.data.list;
        setUserData(responseData);
        console.log("=====response.data.data.list==", response.data.data.list);
        setTotalItems(response?.data?.data.total);

        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

       
          swal({ icon: "error", text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear();
            window.location.href = "/";
            console.log("OK button clicked after error alert");
          });
        
      } else {
        if (error?.response?.status == 403) {
          setLoader(false);
          swal({
            icon: "error",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
            button: "OK",
          }).then((value) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          setLoader(false);
          swal({
            icon: "error",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
            button: "OK",
          }).then((value) => {
            console.log("OK button clicked after error alert");
          });
        }
      }
    }
  }
  async function get_allusers() {
    setLoader(true);
    try {
      const response = await apiService.allget_users(
      
      );
      if (response?.status == 200) {
        localStorage.setItem("previousPath", "/users");
        // let responseData = response.data.data.list;
        console.log("=====response.data.data.listresponseData==", response.data.data);
        let responseData = response.data.data.list;
        setalluserData(responseData);


        // setTotalItems(response?.data?.data.total);

        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          swal({ icon: "error", text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear();
            window.location.href = "/";
            console.log("OK button clicked after error alert");
          });
        }
      } else {
        if (error?.response?.status == 403) {
          setLoader(false);
          swal({
            icon: "error",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
            button: "OK",
          }).then((value) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          setLoader(false);
          swal({
            icon: "error",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
            button: "OK",
          }).then((value) => {
            console.log("OK button clicked after error alert");
          });
        }
      }
    }
  }
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    // Create a new Date object
    const dateObject = new Date(dateValue);
    // Convert to ISO string and ensure it's in UTC
    const isoString = dateObject.toISOString();
    setexpiryDate(isoString);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  async function addVC() {
    console.log('====isChecksss====',isCheck)
    let validate = validateService.validateVC(
      selectedUsers,
      virtualCredits,
     expiryDate,
      setErrors
    );
    console.log("====validate===", validate);
    if (validate) {
      setLoader(true);
      try {
        let params = {
          userIds: isCheck,
          virtualCredits: virtualCredits,
          expiryDate: expiryDate,
          description: description,
        };

        const response = await apiService.addVC(params);
        if (response?.status == 200) {
           handleClose()
           setIsCheck([])
           setIsCheckObj([])
           isCheckRef.current = []
           setSelectedUsers([])
           setvirtualCredits()
           setexpiryDate()
     
          get_users(page, size, role, searchValue, statusEnabled);
          swal(t("success"),t("success_credit"),"success")

          setLoader(false);
        }
      } catch (error) {
        if (error?.response?.status == 401) {
          setLoader(false);

          if (error?.response?.data?.token) {
            localStorage.setItem("admin_token", error?.response?.data?.token);
            window.location.reload();
          } else {
            swal({ icon: "error", text: t("unauth"), button: "OK" }).then(
              () => {
                localStorage.clear();
                window.location.href = "/";
                console.log("OK button clicked after error alert");
              }
            );
          }
        } else {
          if (error?.response?.status == 403) {
            setLoader(false);
            swal({
              icon: "error",
              text: error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message,
              button: "OK",
            }).then((value) => {
              localStorage.clear();
              window.location.href = "/";
            });
          } else {
            setLoader(false);
            swal({
              icon: "error",
              text: error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message,
              button: "OK",
            }).then((value) => {
              console.log("OK button clicked after error alert");
            });
          }
        }
      }
    }
  }

 
  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber);
    setPage(pageNumber - 1);
    get_users(pageNumber - 1, size, role, searchValue, statusEnabled);
  }

  function handleUpdateSearch(event) {
    console.log(event);
    setSearchValue(event.target.value);

    setPage(0);
    // get_users(page, size, role, search)
  }
  function handleRemoveSearch(event) {
    console.log(event);
    setSearchValue("");

    setPage(0);
    get_users(page, size, role, "", statusEnabled);
  }
  function handleUpdateStatus(enablestatus) {
    setPage(0);
    console.log(enablestatus);

    setstatusEnabled(enablestatus);

    get_users(page, size, role, searchValue, enablestatus);
  }

  async function userStatus(id) {
    setLoader(true);
    try {
      // const params = show ? { reason: deactivatedReason } : {}

      // const response=await distrixService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await apiService.ChangeStatus(id);
      if (response.status == 200) {
        get_users(page, size, role, searchValue, statusEnabled);
        setLoader(false);
        swal("Success", response?.data?.message, "success").then(() => {});
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 401) {
        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          swal({ icon: "error", text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear();
            window.location.href = "/";
            console.log("OK button clicked after error alert");
          });
        }
      } else {
      }
    }
  }
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
   
  
    setIsCheck(userData.map(li => parseInt(li.id)));
    const allUsers = userData.map(user => ({ value: user.id, label: user.firstName }));
      setSelectedUsers(allUsers);
      setIsCheckObj(allUsers)
      isCheckRef.current=userData.map(li => parseInt(li.id))
       console.log("======eee======",e)
    // handleUsersSelected(userData)
    if (isCheckAll) {
      setIsCheck([]);
      setIsCheckObj([])
      isCheckRef.current = []
    }
  };
  const handleUsersSelected = (users) => {
    console.log("===users==", users);
    setIsCheck(users)
    setSelectedUsers(users);
  };
  const handleClick = e => {

    const { id, checked } = e.target;
    console.log("======id===",id)
   
  
    if (!checked) {
      const newElement = { label: e.target.dataset.id, value: parseInt(id) };
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
      setSelectedUsers(selectedUsers.filter(item => item !== parseInt(id)));
    
      isCheckRef.current = isCheckRef.current.filter((item) => item !== parseInt(id));
      
      setIsCheckObj((prevState) => {
        // Check if the id already exists in the state
        const exists = prevState.some(item => parseInt(item.value) === parseInt(id));
        console.log("======exists=====", exists);
  
        if (!exists) {
          // If it doesn't exist, add the new element
          return [...prevState, newElement];
        } else {
          // If it exists, remove the element
          return prevState.filter(item => parseInt(item.value) !== parseInt(id));
        }
      });
  
    
    }else{
      setIsCheckObj((prevState) => {
        // Check if the id already exists in the state
        const newElement = { label: e.target.dataset.id, value: parseInt(id) };
        const exists = prevState.some(item => parseInt(item.value) === parseInt(id));
        console.log("======exists=====", exists);
  
        if (!exists) {
          // If it doesn't exist, add the new element
          return [...prevState, newElement];
        } else {
          // If it exists, return the existing state
          return prevState;
        }
      });
     
      if(!isCheck.includes(id)){
  
      setIsCheck([...isCheck, parseInt(id)]);
      }
      if(!selectedUsers.includes(id)){
      setSelectedUsers([...selectedUsers, id])
      }
      if(!isCheckRef.current.includes(id)){
       isCheckRef.current.push(parseInt(id))
      }
    
    }
  };
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">
                      {t("user_managemnet")}
                    </h2>
                  </div>
                  <div className="heading-top-area-right">
                    <div className="me-2">
                      <label>{t("status")}</label>
                      <div className="arrow-down-area">
                        <select
                          className="form-select"
                          onChange={(e) => handleUpdateStatus(e.target.value)}
                        >
                          <option value="">{t("all")}</option>
                          <option value={true}>{t("active")}</option>
                          <option value={false}>{t("suspend")}</option>

                          {/* <option value="Trucker" >Driver List</option> */}
                        </select>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="search-area">
                      <label>{t("search")}</label>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {" "}
                        <input
                          type="text"
                          className="form-control inner-input"
                          value={searchValue}
                          onChange={handleUpdateSearch}
                          onKeyDown={(e) => {
                            // if (!e.target.value && e.code === "Space") {
                            //   e.preventDefault();
                            // }
                            if (e.key === "Enter" && searchValue != "") {
                              get_users(
                                page,
                                size,
                                role,
                                searchValue,
                                statusEnabled
                              );
                            }
                          }}
                          placeholder={t("search-by-name")}
                        />
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          onClick={() =>
                            get_users(
                              page,
                              size,
                              role,
                              searchValue,
                              statusEnabled
                            )
                          }
                        ></i>
                        {searchValue != "" && (
                          <i
                            className="fa fa-close"
                            aria-hidden="true"
                            onClick={() => handleRemoveSearch()}
                          ></i>
                        )}
                      </form>
                    </div>
                    {/* <div className="export-csv-btn">
                    <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <Tabs
                  defaultActiveKey={defaultRole}
                  id="uncontrolled-tab-example"
                  className="prediction-tab mb-3"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="ROLE_CULTIVATOR" title={t("cultivator")}>
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>{t("sr_no")}</th>
                            <th>{t("fname")}</th>
                            <th>{t("lname")}</th>
                            <th className="email-section">{t("email")}</th>
                            <th>{t("phone")}</th>
                            <th>{t("address")}</th>
                            {/* <th>Rejection Reason</th> */}
                            <th>{t("status")}</th>
                            <th>{t("action")}</th>
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {userData &&
                            userData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {data?.firstName ? data?.firstName : "-"}
                                  </td>
                                  <td>
                                    {data?.lastName ? data?.lastName : "-"}
                                  </td>
                                  <td>{data.email}</td>
                                  <td>{data?.phone ? data?.phone : "-"}</td>
                                  <td>
                                    <p className="address-text">
                                      {data?.address ? data?.address : "-"}
                                    </p>
                                  </td>

                                  <td>
                                    <div className="status-btns">
                                      {data.status == "Pending" ? (
                                        <Link
                                          to={"/cultivator-details/" + data.id}
                                          className="delivered waiting"
                                        >
                                          <i class="fa-solid fa-clock"></i>{" "}
                                          {t("pending")}
                                        </Link>
                                      ) : (
                                        <>
                                          {data.status == "Accept" ? (
                                            <Link className="delivered">
                                              <i class="fa-solid fa-check"></i>{" "}
                                              {t("accepted")}
                                            </Link>
                                          ) : (
                                            <>
                                              {data.status == "Reject" ? (
                                                <Link className="delivered rejected">
                                                  <i class="fa-solid fa-close"></i>{" "}
                                                  {t("rejected")}
                                                </Link>
                                              ) : (
                                                <Link className="delivered waiting">
                                                  {t("incomplete_profile")}
                                                </Link>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    {data?.reason ? (
                                      <span
                                        className="show-reason"
                                        onClick={() =>
                                          handleShowReason(data?.reason)
                                        }
                                      >
                                        {t("incomplete_profile")}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <div className="butto-area">
                                      <div className="action-boxes">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltip}
                                        >
                                          <Link
                                            to={
                                              "/cultivator-details/" + data.id
                                            }
                                          >
                                            <i class="fa-solid fa-eye"></i>
                                          </Link>
                                        </OverlayTrigger>
                                        {/* <Link to="/" className="ms-2">
                                       </>:
                                        null
                                         } 
                                <div className="action-boxes">
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                  <Link to={"/user-details/"+data.id}>
                                    <i class="fa-solid fa-eye"></i>
                                  </Link>
                                </OverlayTrigger>
                                {/* <Link to="/" className="ms-2">
                                  <i class="fa-solid fa-trash red"></i>
                                </Link> */}
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            data?.enabled
                                              ? tooltiActive
                                              : tooltiSuspended
                                          }
                                        >
                                          <Form.Check
                                            type="switch"
                                            id={"custom-switch" + data.id}
                                            checked={
                                              data?.enabled ? true : false
                                            }
                                            onClick={(e) =>
                                              swal({
                                                title: t("confirm"),
                                                text: `${
                                                  data?.enabled
                                                    ? t("disable_confirm")
                                                    : t("enable_confirm")
                                                }`,
                                                icon: "warning",
                                                buttons: [t("no"), t("yes")],
                                              }).then((value) => {
                                                if (value) {
                                                  // userStatus(data?.id)
                                                  // setUserId(data?.id)
                                                  // if (data?.is_active == true) {
                                                  //   handleShow()
                                                  // } else {
                                                  userStatus(data?.id);
                                                  // }
                                                } else {
                                                  console.log(
                                                    "NO button clicked or popup closed"
                                                  );
                                                }
                                              })
                                            }
                                          />
                                        </OverlayTrigger>
                                        {/* <Form.Check 
                                  type="switch"
                                  id="custom-switch"
                                  label=""
                                 /> */}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          {/* No data found images add here start */}
                          {userData.length == 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center">
                                  <div className="no-data-found-section">
                                    <img
                                      src={
                                        require("../../assets/images/no-data.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                    <h5>
                                      <b>{t("no_data_found")}</b>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                          {/* No data found images add here end */}
                        </tbody>
                      </Table>

                      <> </>

                      {/* </div> */}
                    </div>
                    {userData.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page + 1}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </Tab>
                  <Tab eventKey="ROLE_DRIVER" title={t("driver")}>
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>{t("sr_no")}</th>
                            <th>{t("full_name")}</th>
                            <th className="email-section">{t("email")}</th>
                            <th>{t("phone")}</th>
                            <th>{t("address")}</th>
                            <th>{t("status")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData &&
                            userData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {data?.firstName
                                      ? data?.firstName + " " + data?.lastName
                                      : "-"}
                                  </td>
                                  <td>{data?.email ? data?.email : "-"}</td>
                                  <td>{data?.phone ? data?.phone : "-"}</td>
                                  <td>
                                    <p className="address-text">
                                      {data?.address ? data?.address : "-"}
                                    </p>
                                  </td>
                                  <td>
                                    <div className="status-btns">
                                      {data.status == "Pending" ? (
                                        <Link
                                          to={"/driver-details/" + data.id}
                                          className="delivered waiting"
                                        >
                                          <i class="fa-solid fa-clock"></i>{" "}
                                          {t("pending")}
                                        </Link>
                                      ) : (
                                        <>
                                          {data.status == "Accept" ? (
                                            <Link className="delivered">
                                              <i class="fa-solid fa-check"></i>{" "}
                                              {t("accepted")}
                                            </Link>
                                          ) : (
                                            <>
                                              {data.status == "Reject" ? (
                                                <Link className="delivered rejected">
                                                  <i class="fa-solid fa-close"></i>{" "}
                                                  {t("rejected")}
                                                </Link>
                                              ) : (
                                                <Link className="delivered waiting">
                                                  {t("incomplete_profile")}
                                                </Link>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                      {/* <Link className="delivered rejected"><i class="fa-solid fa-close"></i> Rejected</Link>
                                <Link className="delivered waiting"><i class="fa-solid fa-clock"></i> Waiting</Link> */}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="butto-area">
                                      <div className="action-boxes">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltip}
                                        >
                                          <Link
                                            to={"/driver-details/" + data.id}
                                          >
                                            <i class="fa-solid fa-eye"></i>
                                          </Link>
                                        </OverlayTrigger>

                                        <Form.Check
                                          type="switch"
                                          id={"custom-switch" + data.id}
                                          onClick={(e) =>
                                            swal({
                                              title: t("confirm"),
                                              text: `${
                                                data?.enabled
                                                  ? t("disable_confirm")
                                                  : t("enable_confirm")
                                              }`,
                                              icon: "warning",
                                              buttons: [t("no"), t("yes")],
                                            }).then((value) => {
                                              if (value) {
                                                // userStatus(data?.id)
                                                // setUserId(data?.id)
                                                // if (data?.is_active == true) {
                                                //   handleShow()
                                                // } else {
                                                userStatus(data?.id);
                                                // }
                                              } else {
                                                console.log(
                                                  "NO button clicked or popup closed"
                                                );
                                              }
                                            })
                                          }
                                          checked={data?.enabled ? true : false}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          {userData.length == 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center">
                                  <div className="no-data-found-section">
                                    <img
                                      src={
                                        require("../../assets/images/no-data.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                    <h5>
                                      <b>{t("no_data_found")}</b>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <> </>

                      {/* </div> */}
                    </div>

                    {userData.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page + 1}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </Tab>
                  <Tab eventKey="ROLE_USER" title={t("customer")}>
                    <div className="virtual-balance-box">
                      <Link className="add-virtual" onClick={handleShow}>
                        <i className="fa-solid fa-plus me-1"></i> {t("add_credit")}
                      </Link>
                    </div>
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>
                              <div className="add-virtual-credit">
                                <Form.Check inline  type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onClick={handleSelectAll}
                                isChecked={isCheckAll}
                                
                                />
                                {/* <Link className="add-virtual" onClick={handleShow}><i className="fa-solid fa-plus me-1"></i> Add</Link> */}
                              </div>
                            </th>
                            <th>{t("sr_no")}</th>
                            <th>{t("full_name")}</th>
                            <th>{t("phone")}</th>
                            <th>{t("vc_bal")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData &&
                            userData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  { console.log("======isCheck======",isCheckRef.current)}
                                  { console.log("======isCheck===data.id===",data.id)}
                                  { console.log("======isCheck==includes====",isCheckRef.current.includes(data.id))}
                                  <td>
                                    <div className="add-virtual-credit">
                                      <Form.Check
                                        inline
                                        // label={data.firstName}
                                        key={data.id}
                                        type="checkbox"
                                        name={data.email}
                                        id={data.id}
                                        onClick={handleClick}
                                        data-id={data.firstName}
                                        checked={isCheckRef.current.includes(data.id)}

                                      />
                                      
                                    </div>
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>
                                    {data?.firstName
                                      ? data?.firstName + " " + data?.lastName
                                      : "-"}
                                  </td>
                                  {/* <td>{data?.email ? data?.email : "-"}</td> */}
                                  <td>{data?.phone ? data?.phone : "-"}</td>
                                  <td>
                                    <p>
                                      <span>$</span>{" "}
                                      {formatToColombianCurrency(
                                        data.virtualCredits
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <div className="butto-area">
                                      <div className="action-boxes">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltip}
                                        >
                                          <Link to={"/user-details/" + data.id}>
                                            <i class="fa-solid fa-eye"></i>
                                          </Link>
                                        </OverlayTrigger>
                                        {/* <Link to="/" className="ms-2">
                        {userData && userData.map((data,index)=>{
                             return(<tr key ={index}>
                                    <td>{index+1}</td>
                                    <td>{data?.name?data?.name:"-"}</td>
                                    <td>{data?.email?data?.email:"-"}</td>
                                    <td>{data?.phone?data?.phone:"-"}</td>
                                 
                                     <td>
                                      <div className="butto-area">
                                     
                                    
                                        <div className="action-boxes">
                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                          <Link to={"/user-details/"+data.id}>
                                            <i class="fa-solid fa-eye"></i>
                                          </Link>
                                        </OverlayTrigger>
                                        {/* <Link to="/" className="ms-2">
                                          <i class="fa-solid fa-trash red"></i>
                                        </Link> */}
                                        <Form.Check
                                          type="switch"
                                          id={"custom-switch" + data.id}
                                          checked={data?.enabled ? true : false}
                                          onClick={(e) =>
                                            swal({
                                              title: t("confirm"),
                                              text: `${
                                                data?.enabled
                                                  ? t("disable_confirm")
                                                  : t("enable_confirm")
                                              }`,
                                              icon: "warning",
                                              buttons: [t("no"), t("yes")],
                                            }).then((value) => {
                                              if (value) {
                                                // userStatus(data?.id)
                                                // setUserId(data?.id)
                                                // if (data?.is_active == true) {
                                                //   handleShow()
                                                // } else {
                                                userStatus(data?.id);
                                                // }
                                              } else {
                                                console.log(
                                                  "NO button clicked or popup closed"
                                                );
                                              }
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          {userData.length == 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center">
                                  <div className="no-data-found-section">
                                    <img
                                      src={
                                        require("../../assets/images/no-data.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                    <h5>
                                      <b>{t("no_data_found")}</b>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      <> </>

                      {/* </div> */}
                    </div>
                    {userData.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page + 1}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title>
              <b>{t("add_credit")}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="virtual-box">
              <div className="mb-3">
                <MultiSelect
                  userData={userData}
                  onUsersSelected={handleUsersSelected}
                  checkSelectedUser={isCheck}
                  checkSelectedUserData={IsCheckObj}
                  setIsCheckObj={setIsCheckObj}
                  setIsCheck={setIsCheck}
                  isCheckRef={isCheckRef}


                />
                {errors.selectedUsers && (
                  <p className="err-msg ">{t(errors.selectedUsers)}</p>
                )}
              </div>
              {/* <div className="mb-3">
                <label class="form-label">
                  <b>{t("message")}</b>
                </label>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label={t("enter_message")}
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                  {errors.message && (
                    <p className="err-msg ">{t(errors.message)}</p>
                  )}
                </FloatingLabel>
              </div> */}
              <div className="mb-3">
                <label class="form-label">
                  <b>{t("amount")} ($)</b>
                </label>
                <Form.Control
                  type="text"
                  placeholder={t("enter_amount")}
                  onChange={(e) => setvirtualCredits(e.target.value)}
                />
                {errors.amount && <p className="err-msg ">{t(errors.amount)}</p>}
              </div>
              <div className="mb-3">
                <label class="form-label">
                  <b>{t("expire")}</b>
                </label>
                <Form.Control
                  type="date"
                  min={getCurrentDate()}
                  placeholder={t("date_selecte")}
                  onChange={(e) => handleDateChange(e)}
                />
                {errors.expiration && (
                  <p className="err-msg ">{t(errors.expiration)}</p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={addVC}>
              {t("save")}
            </Button>
            <Button
              variant="secondary"
              className="close-btn-bottom"
              onClick={handleClose}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showReason} onHide={handleCloseReason} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{t("reject_reason")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{deactivatedReasonShow}</p>
          </Modal.Body>
          <Modal.Footer className="pt-3">
            <Button
              variant="primary"
              onClick={handleCloseReason}
              className="close-btn"
            >
              {t("close")}
            </Button>
            {/* <Button variant="primary" onClick={disableUser}>
             Submit
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
